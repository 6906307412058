import { useEffect, useState } from 'react';

import {
  ActionPopUpSingleButton,
  ChipBadge,
  IStyles,
  ImageWrapper,
  InfoDisplayPanel,
  LockToggleButton,
  NoContentCard,
  deserify,
  firstLetterImage,
  getHumanReadableTimestampString,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Pagination, Typography } from '@mui/material';
import { useAppDispatch } from '../../../../reduxStore/reduxHooks';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';

import { LessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { HOME } from '../../../../routeHandling/RoutesNomenclature';
import { getSubjectsMap } from '../../../../utils/icons';
import { onLessonCardClick } from '../../../../utils/learn';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';

import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import {
  ContentLockStatusType,
  Feature,
  LessonNodeLock,
} from '@protos/school_management/school.db_pb';

interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

const styles: IStyles = {
  root: {
    paddingX: { xs: pxToRem(0), md: pxToRem(20), lg: pxTovW(240) },
    pb: { xs: pxToRem(20), md: pxTovW(10) },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    marginY: { xs: pxToRem(21), md: pxTovW(16) },
    marginLeft: { xs: pxToRem(30), md: 0 },
    '&  .MuiChip-label': {
      fontSize: { xs: pxToRem(16), lg: pxTovW(20) },
      fontWeight: 'medium',
    },
  },
  contentBox: {
    boxSizing: 'border-box',
    p: { xs: pxToRem(20), md: pxTovW(20) },
    width: { xs: '100%', md: '100%' },
    marginX: 'auto',
    height: { xs: 'max-content' },
    overflow: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' },
    background: {
      xs: 'white',
      md: 'linear-gradient(to bottom, #FDFBFB 20%,#EAF4FC 20% 80%)',
    },
  },
  grid: {
    height: '100%',
    width: { xs: '100%', md: '100%' },
    justifyContent: { xs: 'center', md: 'center' },
    display: 'flex',
    flexWrap: 'wrap',
    gap: { xs: pxToRem(25), md: pxTovW(0) },
  },
  boxItem: {
    justifyContent: 'center',
    marginX: { md: pxTovW(20) },
  },
  pagination: {
    width: 'max-content',
    margin: 'auto',
    mt: { xs: pxToRem(20), md: pxTovW(30) },
  },
};

export const SeeAllLessonPlan = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const { isIpadOnly } = useGlobalContext();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { setSelectedFunction } = useGlobalContext();
  const { userInfo } = deserify(useAppSelector((state) => state.auth));
  const { selected_subject_id } = deserify(
    useAppSelector((state) => state.home)
  );

  const subMap = getSubjectsMap(userInfo?.learnSubjects || []);
  const [pageNumber, setPageNumber] = useState(1);
  const [lessonPlanList, setLessonPlanList] = useState<LessonInfo[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalLesson, setTotalLesson] = useState(0);
  const ITEMS_PER_PAGE = 12;
  const {
    LessonLearnAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const [lockedData, setLockedData] = useState<LessonNodeLock[]>();
  const [actionPopupState, setActionPopupState] = useState(false);
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  // const isContentLockFeature = useFeatureEnabled(
  //   featureInfo,
  //   Feature.CONTENT_LOCK
  // );
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });

  const handleChange = async (e: React.ChangeEvent<unknown>, p: number) => {
    setPageNumber(p);
    if (pageNumber !== p) {
      await interactionEvent({
        url: 'Student_See_All_Lesson_Plan',
        context: 'navigation_bar',
        name: 'PAGE_CHANGE',
      });
    }
  };

  const backButtonClick = async () => {
    navigate(HOME);
  };
  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  useEffect(() => {
    getStudentOngoingLessonList();
  }, [selected_subject_id, pageNumber]);

  async function getStudentOngoingLessonList() {
    try {
      if (!userInfo?.studentProfileId) {
        return;
      }
      setLoading(true);
      const response =
        await LessonLearnAPIServiceV1ClientWithStatusCodeHandler.getStudentOngoingLessonList(
          {
            studentId: userInfo?.studentProfileId,
            subjectId: selected_subject_id,
            sectionId: userInfo?.classSectionDetails?.sectionId,
            pageNumber: pageNumber,
            itemsPerPage: ITEMS_PER_PAGE,
            // itemsPerPage: 1,
          }
        );
      if (response) {
        setLoading(false);

        if (response.data) {
          const lpList = response.data.lessons.sort(
            (a, b) =>
              (b.lastSessionTime?.toDate().getTime() ?? 0) -
              (a.lastSessionTime?.toDate().getTime() ?? 0)
          );
          setLessonPlanList(lpList);
          const lessonPlanIds = lpList?.map((lesson) => lesson.lessonId);
          // if (isContentLockFeature && studentId) {
          //   await fetchLockContent(lessonPlanIds);
          // }
          setTotalPages(response.data.paginationData?.totalPages || 1);
          setTotalLesson(
            response.data.paginationData?.totalLessons || lpList.length || 0
          );
          return;
        }
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  }

  // const fetchLockContent = async (lessonPlanIds: string[]) => {
  //     const response =
  //       await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getLockStatusByIds(
  //         {
  //           personId: studentId,
  //           personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
  //           subjectId: selected_subject_id,
  //           schoolClassSectionId: userInfo?.classSectionDetails?.sectionId,
  //           lessonIds: lessonPlanIds,
  //         }
  //       );
  //     if (response) {
  //       setLockedData(response.lessonInfo);
  //     }
  //   } catch (err) {
  //     throw err;
  //   }
  // };
  const showLockPopup = () => {
    setPopupDetails({
      iconName: 'locked@2x',
      buttontext: 'ok',
      popupText:
        'Your school has locked this lessonplan. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };

  if (lessonPlanList.length === 0)
    return (
      <Box sx={{ width: '100%', p: { xs: pxToRem(20), md: pxTovW(40) } }}>
        <NoContentCard variant="info" icon="cards" text="No cards to show" />
      </Box>
    );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.titleBox}>
        <Typography variant="h1" fontWeight="medium">
          Ongoing Lessons
        </Typography>
        <ChipBadge label={totalLesson} color="primary" size={'small'} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          columnGap: { xs: pxToRem(20), md: pxTovW(100), lg: pxTovW(40) },
          rowGap: { xs: pxToRem(20), md: pxTovW(40) },
          background: {
            md: 'linear-gradient(to bottom, #FDFBFB 8%, #EAF4FC 8%, #EAF4FC 92%, #FDFBFB 92%)',
          },
        }}
      >
        {lessonPlanList.map((val, index) => (
          // <Grid item xs={12} sm={6} md={4} lg={3} sx={{}}>
          <Box component={'div'}>
            <InfoDisplayPanel
              defaultImage="lessonplan-v1"
              key={'home_ongoing_lesson_' + index}
              variant="large"
              blueSubText={subMap[val.subjectId.toString()]?.subjectName} // full subject name and grade missing
              lastSessionTime={
                val.lastSessionTime
                  ? 'Taught ' +
                    getHumanReadableTimestampString(val.lastSessionTime)
                  : ''
              }
              mainHeading={val.title}
              iconDetails={[
                {
                  iconName: 'clock',
                  text: `${val.estimatedTimeInMin || 0} Min`,
                },
                {
                  iconName: 'questions',
                  text: `${val.resourceIds.length} resources`,
                },
              ]}
              rootStyle={{
                backgroundColor: 'common.white',
                width: {
                  md: isIpadOnly ? pxTovW(350) : pxTovW(277),
                },
                height: {
                  md: isIpadOnly ? pxTovW(420) : pxTovW(314),
                },
              }}
              image={getMediaBasePath(
                val.posterImageUrl,
                'processedMediaBucket'
              )}
              cardClickHandler={() => {
                // isContentLockFeature &&
                getLessonContentLockInfo(val.lessonId, lockedData)
                  ?.lockStatus ===
                ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                  ? showLockPopup()
                  : onLessonCardClick(
                      dispatch,
                      navigate,
                      {
                        subjectId: val.subjectId,
                        lessonId: val.lessonId,
                        topicId: val.moduleId,
                        chapterId: val.parentModuleId,
                      },
                      location.pathname
                    );
              }}
              status={<LessonPlanInfoPanelStatus lesson={val} />}
              // isLocked={
              //   isContentLockFeature
              //     ? getLessonContentLockInfo(val.lessonId, lockedData)
              //         ?.lockStatus ===
              //       ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
              //     : false
              // }
            >
              <Box
                sx={{
                  position: 'absolute',
                  right: { xs: pxToRem(18), md: pxTovW(20) },
                  top: { xs: pxToRem(18), md: pxTovW(20) },
                }}
              >
                {/* {isContentLockFeature && (
                  <LockToggleButton
                    isLocked={
                      lockedData
                        ? getLessonContentLockInfo(val.lessonId, lockedData)
                            ?.lockStatus
                        : false
                    }
                  />
                )} */}
              </Box>
            </InfoDisplayPanel>
          </Box>
        ))}
      </Box>
      {lessonPlanList.length > 0 && (
        <Pagination
          page={pageNumber}
          count={totalPages}
          color="secondary"
          onChange={handleChange}
          sx={styles.pagination}
        />
      )}
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </Box>
  );
};

interface InfoDisplayPanelStatus {
  lesson: LessonInfo;
}
const LessonPlanInfoPanelStatus = ({ lesson }: InfoDisplayPanelStatus) => {
  const taughtTime = getHumanReadableTimestampString(
    lesson.lastSessionTime
  )?.split(' ');

  if (!lesson.teacherName) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
        pt: { xs: pxToRem(5), md: pxTovW(5) },
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: pxToRem(5), md: pxTovW(5) },
        }}
      >
        <ImageWrapper
          name={lesson.teacherName || 'Geneo'}
          type="png"
          parentFolder="icons"
          styles={{
            width: { xs: pxToRem(18), md: pxTovW(28) },
            height: { xs: pxToRem(18), md: pxTovW(28) },
            borderRadius: '50%',
          }}
          path={
            getMediaBasePath(lesson.teacherProfileImageUrl) ||
            firstLetterImage(lesson.teacherName) ||
            firstLetterImage('Geneo')
          }
        />

        <Typography variant="smallText" fontWeight="bold">
          {lesson.teacherName || 'Geneo'}
        </Typography>
      </Box>

      {taughtTime && lesson.teacherName && (
        <Typography variant="subText" color="text.disabled">
          {Number(taughtTime[0]) < 2
            ? 'Taught Today'
            : 'Taught ' + taughtTime?.join(' ')}
        </Typography>
      )}
    </Box>
  );
};
const getLessonContentLockInfo = (
  lessonId: string,
  contentLockData?: LessonNodeLock[]
) => {
  return contentLockData?.find((val) => val.lessonId === lessonId);
};
