import {
  ChipBadge,
  ContentDetailCard,
  IStyles,
  LockToggleButton,
  NoContentCard,
  SectionListWithTopCarousel,
  deserify,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  resourceTypeName,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import {
  ResourceCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { ResourceSelectionFromTypeEnum } from '@protos/geneo_ai/ai.student.events_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { SessionModeEnum } from '@protos/learning_management/lms.db_pb';
import { Feature } from '@protos/school_management/school.db_pb';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../../../reduxStore/reduxHooks';
import { onResourceClick } from '../../../../../utils/learn';
import { interactionEvent } from '../../../../Auth/Login/login_logout.events';
import { aiResourceOpenEvent } from '../../../utils_learn/learn.events';

const styles: IStyles = {};

interface ISelectedChapterResources {
  loading: boolean;
  error: boolean;
  lockedData?: ContentLockModuleData;
  showLockPopup: () => void;
}

export default function SelectedChapterResources(
  props: ISelectedChapterResources
) {
  const { loading, error, lockedData, showLockPopup } = props;
  const { subject_id, chapter_id } = useParams();
  const subjectId = Number(subject_id);
  const chapterId = Number(chapter_id);
  const { isIpadOnly } = useGlobalContext();
  const navigate = useNavigate();
  const selectedChapterResources = deserify(
    useAppSelector((state) => state.learn.selected_chapter_resources)
  );
  const dispatch = useDispatch();
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: pxTovW(2),
          padding: { xs: pxToRem(20), md: 0 },
        }}
      >
        <Typography variant="h2">Chapter Resources</Typography>
        <Typography
          variant="h3"
          sx={{ marginLeft: { xs: pxToRem(6), md: pxTovW(9) } }}
        >
          <ChipBadge
            label={
              selectedChapterResources?.categoryResourceMap
                ?.map((obj) => obj.categoryResources.length)
                .reduce((a, b) => a + b, 0) || 0
            }
            color="primary"
            size="small"
          />
        </Typography>
      </Box>
      {loading ? (
        <Typography variant="h2">Loading ...</Typography>
      ) : error ? (
        <NoContentCard variant="error" icon="error" text="Error Occured" />
      ) : selectedChapterResources?.categoryResourceMap.length === 0 ? (
        <NoContentCard
          variant="soon"
          icon="hourglass-web"
          text="Coming Soon!"
          rootStyle={{
            height: { xs: pxToRem(150), md: pxTovW(212) },
            boxSizing: 'border-box',
            mt: { xs: pxToRem(20), md: pxTovW(40) },
          }}
        />
      ) : (
        <Box>
          {selectedChapterResources?.categoryResourceMap.map(
            (category, index) => (
              <SectionListWithTopCarousel
                key={'category_' + index}
                title={category.categoryTitle}
                subtitle={category.categoryDescription}
                items={category.categoryResources.map((resource, rIndex) => (
                  <ContentDetailCard
                    isLocked={
                      isContentLockFeature &&
                      lockedData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
                        resource.resourceId
                      )
                    }
                    locked={
                      isContentLockFeature && (
                        <LockToggleButton
                          isLocked={
                            lockedData &&
                            lockedData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
                              resource.resourceId
                            )
                          }
                        />
                      )
                    }
                    // isLocked ? popup():

                    onClick={async () => {
                      await interactionEvent({
                        url: 'Student_Learn_topic',
                        context: 'Chapter_Resources',
                        name: 'RESOURCE_OPEN',
                      });

                      await aiResourceOpenEvent(
                        resource.resourceId,
                        ResourceSelectionFromTypeEnum.CHAPTER_HOMEPAGE
                      );
                      isContentLockFeature &&
                      lockedData &&
                      lockedData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
                        resource.resourceId
                      )
                        ? showLockPopup()
                        : onResourceClick(
                            navigate,
                            {
                              resourceId: resource.resourceId,
                              subjectId,
                              chapterId,
                            },
                            SessionModeEnum.SESSION_MODE_LEARN
                          );
                    }}
                    key={'category_' + index + '_resource_' + rIndex}
                    variant={isIpadOnly ? 'large' : 'small'}
                    image={getMediaBasePath(
                      resource.posterImageUrl,
                      resource.resourceCategoryType ===
                        ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                        ? 'userDataBucket'
                        : 'processedMediaBucket'
                    )}
                    showVideoIcon={
                      resource.resourceType ===
                      Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                    }
                    heading={resource.title}
                    iconDetails={[
                      {
                        iconName: 'clock',
                        text: resource.estimatedTimeInMin + ' Min',
                      },
                      {
                        iconName: resourceTypeName(resource.resourceType)?.icon,
                        text: resourceTypeName(resource.resourceType)?.name,
                      },
                    ]}
                  />
                ))}
                containerMdWidth={isIpadOnly ? '100%' : pxTovW(855)}
                itemsToShow={isIpadOnly ? 2 : 3}
              />
            )
          )}
        </Box>
      )}
    </>
  );
}
