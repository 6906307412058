import {
  ChipBadge,
  ContentDetailCard,
  IStyles,
  LockToggleButton,
  NoContentCard,
  SectionListWithTopCarousel,
  deserify,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  resourceTypeName,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import {
  ResourceCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { ResourceSelectionFromTypeEnum } from '@protos/geneo_ai/ai.student.events_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { SessionModeEnum } from '@protos/learning_management/lms.db_pb';
import { Feature } from '@protos/school_management/school.db_pb';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../app/Context/GlobalContextProvider';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../reduxStore/reduxHooks';
import { onResourceClick } from '../../../../../utils/learn';
import {
  getResourceType,
  interactionEvent,
} from '../../../../Auth/Login/login_logout.events';
import { aiResourceOpenEvent } from '../../../utils_learn/learn.events';
import { ResourceShimmer } from '../../Shimmer';

const styles: IStyles = {};

interface ITopicResources {
  loading: boolean;
  error: boolean;
  lockedData?: ContentLockModuleData;
  showLockPopup: () => void;
}

export const TopicResources = (props: ITopicResources) => {
  const { loading, error, lockedData, showLockPopup } = props;
  const { subject_id, topic_id, chapter_id } = useParams();
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const topicId = Number(topic_id);
  const chapterId = Number(chapter_id);
  const subjectId = Number(subject_id);
  const { isIpadOnly } = useGlobalContext();
  const { selected_topic_resources } = deserify(
    useAppSelector((state) => state.learn)
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(10), md: pxTovW(10), alignItems: 'center' },
          paddingLeft: { xs: pxToRem(20), md: 0 },
        }}
      >
        <Typography variant="h2" fontWeight="bold">
          Resources
        </Typography>
        <ChipBadge
          label={
            selected_topic_resources
              ?.map((obj) => obj.categoryResources.length)
              .reduce((a, b) => a + b, 0) || 0
          }
          color="primary"
          size="small"
        />
      </Box>
      {loading ? (
        <ResourceShimmer />
      ) : error ? (
        <NoContentCard variant="error" icon="error" text="Error Occured" />
      ) : selected_topic_resources?.length === 0 ? (
        <NoContentCard
          variant="soon"
          icon="hourglass-web"
          text="Coming Soon!"
        />
      ) : (
        <Box>
          {selected_topic_resources?.map((topicResource, index) => (
            <SectionListWithTopCarousel
              itemsToShow={2}
              key={'topic_category_' + index}
              title={topicResource.categoryTitle}
              subtitle={topicResource.categoryDescription}
              items={topicResource.categoryResources.map((resource, rIndex) => (
                <ContentDetailCard
                  isLocked={
                    isContentLockFeature &&
                    lockedData &&
                    lockedData.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
                      resource.resourceId
                    )
                  }
                  locked={
                    isContentLockFeature && (
                      <LockToggleButton
                        isLocked={
                          lockedData &&
                          lockedData.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
                            resource.resourceId
                          )
                        }
                      />
                    )
                  }
                  onClick={async () => {
                    await interactionEvent({
                      url: 'Student_topic_lesson',
                      context: topicResource.categoryTitle.toLowerCase(),
                      name: getResourceType(resource.resourceType),
                    });

                    await aiResourceOpenEvent(
                      resource.resourceId,
                      ResourceSelectionFromTypeEnum.TOPIC_HOMEPAGE
                    );
                    isContentLockFeature &&
                    lockedData &&
                    lockedData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
                      resource.resourceId
                    )
                      ? showLockPopup()
                      : onResourceClick(
                          navigate,
                          {
                            resourceId: resource.resourceId,
                            subjectId,
                            chapterId,
                            topicId,
                          },
                          SessionModeEnum.SESSION_MODE_LEARN
                        );
                  }}
                  key={'topic_category_' + index + '_resource_' + rIndex}
                  variant={isIpadOnly ? 'large' : 'small'}
                  image={getMediaBasePath(
                    resource.posterImageUrl,
                    resource.resourceCategoryType ===
                      ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                      ? 'userDataBucket'
                      : 'processedMediaBucket'
                  )}
                  showVideoIcon={
                    resource.resourceType ===
                    Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                  }
                  heading={resource.title}
                  iconDetails={[
                    {
                      iconName: 'clock',
                      text: resource.estimatedTimeInMin + ' Min',
                    },
                    {
                      iconName: resourceTypeName(resource.resourceType)?.icon,
                      text: resourceTypeName(resource.resourceType)?.name,
                    },
                  ]}
                  rootStyle={{
                    backgroundColor: 'common.white',
                  }}
                />
              ))}
              containerMdWidth={isIpadOnly ? '100%' : pxTovW(620)}
            />
          ))}
        </Box>
      )}
    </>
  );
};
