import {
  ActionPopUpSingleButton,
  ContentDetailCard,
  FullWidthSectionList,
  IStyles,
  LockToggleButton,
  deserify,
  getMediaBasePath,
  getResourceCategory,
  pxToRem,
  pxTovW,
  resourceTypeName,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { ResourceInfo } from '@protos/content_management/content.common.apis_pb';
import {
  ResourceCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { ResourceSelectionFromTypeEnum } from '@protos/geneo_ai/ai.student.events_pb';
import { RecommendationReqSendFromTypeEnum } from '@protos/geneo_ai/ai.student.recommendation.apis_pb';
import { SessionModeEnum } from '@protos/learning_management/lms.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { HOME } from '../../../../routeHandling/RoutesNomenclature';
import { onResourceClick } from '../../../../utils/learn';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import SectionListSckeleton, {
  ShimmerActiveHwCard,
} from '../../../Home/shimmer';
import { setResourceRedirectionPath } from '../../../Learn/reducer/learn.slice';
import { aiResourceOpenEvent } from '../../../Learn/utils_learn/learn.events';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import {
  ContentLockStatusType,
  Feature,
} from '@protos/school_management/school.db_pb';
import { ResourceLock } from '@protos/learning_management/lms.common.apis_pb';
const styles: IStyles = {};
interface IProps {
  subjectId?: number;
  topicId?: number;
  chapterId?: number;
}
interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

export const HomeworkRecommendations = (props: IProps) => {
  const { subjectId, topicId, chapterId } = props;
  const [resources, setResources] = useState<ResourceInfo[]>([]);
  const [recommendationId, setRecommendationId] = useState<string | undefined>(
    undefined
  );
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const dispatch = useDispatch();
  const {
    AiStudentAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const [lockedData, setLockedData] = useState<ResourceLock[]>();
  const [actionPopupState, setActionPopupState] = useState(false);
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });

  useEffect(() => {
    fetchRecommendations();
  }, []);
  async function fetchRecommendations() {
    try {
      if (!studentId) {
        return;
      }
      setLoading(true);
      // const response =
      //   await LmsStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentResourceRecommendation({
      //     studentId: studentId,
      //     subjectId,
      //     topicId,
      //     chapterId,
      //   });
      const response =
        await AiStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentResourceRecommendationfromGeneoAi(
          {
            studentId: studentId,
            subjectId,
            topicId,
            chapterId,
            sendFrom:
              RecommendationReqSendFromTypeEnum.HOMEWORK_SUBMITTED_RECOMMENDATION,
          }
        );
      if (response) {
        setLoading(false);
        if (response.data) {
          setResources(response.data?.resources || []);
          setRecommendationId(response.data.recommendationId);
          const resourceIds = response?.data?.resources?.map(
            (resource) => resource.resourceId
          );
          if (isContentLockFeature && studentId) {
            await fetchLockContent(resourceIds);
          }
          return;
        }
      }
      setResources([]);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
      setResources([]);
      // setLoading('error');
    }
  }

  const fetchLockContent = async (resourceIds: string[]) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getLockStatusByIds(
          {
            personId: studentId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            subjectId: selectedSubjectId,
            schoolClassSectionId: user?.classSectionDetails?.sectionId,
            resourceIds: resourceIds,
          }
        );
      if (response) {
        setLockedData(response?.resourceInfo);
      }
    } catch (err) {
      throw err;
    }
  };
  const showLockPopup = () => {
    setPopupDetails({
      iconName: 'locked@2x',
      buttontext: 'ok',
      popupText:
        'Your school has locked this resource. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };

  const getRecommendedResourcesItems = () => {
    if (!resources) {
      return [];
    }
    return resources.map((val, index) => (
      <ContentDetailCard
        isLocked={
          isContentLockFeature
            ? getResourceContentLockInfo(val.resourceId, lockedData)
                ?.lockStatus ===
              ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
            : false
        }
        locked={
          isContentLockFeature && (
            <LockToggleButton
              isLocked={
                lockedData
                  ? getResourceContentLockInfo(val.resourceId, lockedData)
                      ?.lockStatus
                  : false
              }
            />
          )
        }
        tagName={getResourceCategory(val.resourceCategoryType)}
        onClick={async () => {
          dispatch(setResourceRedirectionPath(HOME));

          isContentLockFeature &&
          getResourceContentLockInfo(val.resourceId, lockedData)?.lockStatus ===
            ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
            ? showLockPopup()
            : onResourceClick(
                navigate,
                {
                  resourceId: val.resourceId,
                },
                SessionModeEnum.SESSION_MODE_RESOURCE
              );

          await interactionEvent({
            url: '',
            context: 'recommended_resources',
            name: 'RESOURCE_OPEN',
            pathSegments: pathSegments,
          });
          await aiResourceOpenEvent(
            val.resourceId,
            ResourceSelectionFromTypeEnum.HOMEWORK_SUBMITTED_RECOMMENDATION,
            index + 1,
            recommendationId
          );
        }}
        key={'home_content_detail_' + index}
        variant="medium"
        image={getMediaBasePath(
          val.posterImageUrl,
          val.resourceCategoryType ===
            ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
            ? 'userDataBucket'
            : 'processedMediaBucket'
        )}
        heading={val.title}
        iconDetails={[
          { iconName: 'clock', text: `${val.estimatedTimeInMin} Min` },
          {
            iconName: resourceTypeName(val.resourceType)?.icon,
            text: resourceTypeName(val.resourceType)?.name,
          },
        ]}
        showVideoIcon={
          val.resourceType === Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
        }
        rootStyle={{
          backgroundColor: 'common.white',
          width: { xs: pxToRem(283), md: pxToRem(273), lg: pxTovW(347) },
          height: { xs: pxToRem(103), md: pxToRem(80), lg: pxTovW(112) },
          maxWidth: { xs: '100%', md: pxToRem(273), lg: pxTovW(347) },
        }}
      />
    ));
  };

  return loading === true ? (
    <SectionListSckeleton fullWidth children={ResourceShimmerArray} />
  ) : (
    <>
      <FullWidthSectionList
        isError={error}
        hideListCount
        sectionTitle="Recommended Resources"
        items={getRecommendedResourcesItems()}
        noContentMessage={'No Recommended Resources Available'}
      />
      {/* <SectionListSckeleton fullWidth children={OngoingLsShimmerArray} /> */}
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </>
  );
};

const ResourceShimmerArray = [
  <ShimmerActiveHwCard variant="small" />,
  <ShimmerActiveHwCard variant="small" />,
  <ShimmerActiveHwCard variant="small" />,
  <ShimmerActiveHwCard variant="small" />,
  <ShimmerActiveHwCard variant="small" />,
];
const getResourceContentLockInfo = (
  resourceId: string,
  contentLockData?: ResourceLock[]
) => {
  return contentLockData?.find((val) => val.resourceId === resourceId);
};
