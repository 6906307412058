import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

//
import {
  Documentation,
  FAQElement,
  Notifications,
} from '@geneo2-web/shared-ui';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ServiceClientProvider } from '../../app/Context/ServiceClientsProvider';
import AppLayout from '../../components/AppLayout/AppLayout';
import HalfSplitLayout from '../../components/HalfSplitLayout';
import { ChooseProfile } from '../../pages/Auth/Login/ChooseProfile';
import EnterUserIdentification from '../../pages/Auth/Login/EnterUserIdentification';
import { LoginNew } from '../../pages/Auth/Login/LoginNew';
import { LoginUidPass } from '../../pages/Auth/Login/LoginUidPass';
import { ResetPassword } from '../../pages/Auth/Login/ResetPassword';
import { ResetPasswordOtp } from '../../pages/Auth/Login/ResetPasswordOtp';
import { MCBAppLogin } from '../../pages/Auth/MCBAppLogin/MCBAppLogin';
import { MCBLogin } from '../../pages/Auth/MCBLogin/MCBLogin';
import EnterUserIdRegistration from '../../pages/Auth/Register/EnterUid';
import { OtpScreen } from '../../pages/Auth/Register/OtpScreen';
import { SetPassword } from '../../pages/Auth/Register/SetPassword';
import { VerifyDetails } from '../../pages/Auth/Register/VerifyDetails';
import { VerifyEmail } from '../../pages/Auth/Register/VerifyEmail';
import { VerifyPhone } from '../../pages/Auth/Register/VerifyPhone';
import DemoQuestion from '../../pages/Demo/DemoQuestion';
import DemoResourceCard from '../../pages/Demo/DemoResourceCard';
import DemoResource from '../../pages/DemoResource';
import Home from '../../pages/Home';
import { SeeAllLessonPlan } from '../../pages/Home/components/SeeAllLessonPlan';
import { ActiveHw } from '../../pages/Homework/ActiveHw';
import { CompletedHw } from '../../pages/Homework/CompletedHw';
import { HwStartCover } from '../../pages/Homework/HwStartCover';
import { HwSubmitted } from '../../pages/Homework/HwSubmitted';
import { StudentHwDash } from '../../pages/Homework/StudentHwDash';
import { ViewRecommendations } from '../../pages/Homework/ViewRecommendations';
import LearnDashboard from '../../pages/Learn/LearnDashboard';
import LearnTopicSelection from '../../pages/Learn/LearnTopicSelection';
import { LessonView } from '../../pages/Learn/LessonView';
import ResourceView from '../../pages/Learn/ResourceView';
import { TopicLesson } from '../../pages/Learn/TopicLesson';
import DownloadSettings from '../../pages/OfflineScreens/Learn/DownloadSettings';
import LearnDashboardOffline from '../../pages/OfflineScreens/Learn/LearnDashboard';
import LearnTopicSelectionOffline from '../../pages/OfflineScreens/Learn/LearnTopicSelection';
import LessonViewOffline from '../../pages/OfflineScreens/Learn/LessonView';
import { ResourceViewOffline } from '../../pages/OfflineScreens/Learn/ResourceView';
import { TopicLessonOffline } from '../../pages/OfflineScreens/Learn/TopicLessons';
import { ChapterScorePage } from '../../pages/Performance/ChapterScorePage';
import { PerformanceDashboard } from '../../pages/Performance/PerformanceDashboard';
import { ChangePassword } from '../../pages/Sidebar/ChangePassword';
import { HelpAndSupport } from '../../pages/Sidebar/HelpAndSupport';
import { Profile } from '../../pages/Sidebar/Profile';
import { ProfileOtpScreen } from '../../pages/Sidebar/ProfileOtpScreen';
import { StudentDisclaimer } from '../../pages/Sidebar/StudentDisclaimer';
import {
  CHANGE_PASSWORD,
  CHOOSE_PROFILE,
  DEMO_QUESTION,
  DEMO_RESOURCE,
  DEMO_RESOURCE_CARD,
  DISCLAIMER,
  DOCS,
  DOWNLOAD_SETTINGS,
  ENTER_UID,
  ENTER_UID_VK12,
  FAQ,
  HELP,
  HOME,
  HOMEWORK_ACTIVE,
  HOMEWORK_COMPLETED,
  HOMEWORK_START_COVER,
  HOMEWORK_STUDENT_DASHBOARD,
  HOMEWORK_SUBMITTED,
  HOMEWORK_VIEW_RECOMMENDATION,
  LEARN_DASHBOARD,
  LEARN_TOPIC_SELECTION,
  LESSON_VIEW,
  LOGIN,
  LOGIN_PASSWORD,
  LOGIN_UID_PASSWORD,
  MCB_APP_LOGIN,
  MCB_LOGIN,
  NOTIFICATION,
  OFFLINE_LEARN_DASHBOARD,
  OFFLINE_LEARN_TOPIC_SELECTION,
  OFFLINE_LESSON_VIEW,
  OFFLINE_RESOURCE_VIEW,
  OFFLINE_TOPIC_LESSON,
  OTP_SCREEN,
  PERFORMANCE_STUDENT_CHAPTERWISE,
  PERFORMANCE_STUDENT_DASHBOARD,
  PROFILE,
  PROFILE_OTP_SCREEN,
  REGISTRATION,
  RESET_PASSWORD,
  RESET_PASSWORD_OTP,
  RESOURCE_VIEW,
  SEE_ALL_LESSON_PLAN,
  SET_PASSWORD,
  TOPIC_LESSON,
  VERIFY_DETAILS,
  VERIFY_EMAIL,
  VERIFY_PHONE,
  VK12_LOGIN_PASSWORD,
} from '../RoutesNomenclature';
import PrivateRouterWrapper from './PrivateRoutersWrapper';
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<ServiceClientProvider />}>
      {/* //! ------------- A U T H ------------- */}
      {/* <Route path={LOGIN} element={<LoginPage />} /> */}
      <Route path="/" element={<HalfSplitLayout />}>
        <Route path={'/'} element={<Navigate to={ENTER_UID} replace />} />
        <Route path={LOGIN} element={<Navigate to={ENTER_UID} replace />} />
        <Route path={ENTER_UID} element={<EnterUserIdentification />} />
        <Route path={LOGIN_PASSWORD} element={<LoginNew />} />
        <Route path={RESET_PASSWORD_OTP} element={<ResetPasswordOtp />} />
        <Route path={RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={REGISTRATION} element={<EnterUserIdRegistration />} />
        <Route path={VERIFY_DETAILS} element={<VerifyDetails />} />
        <Route path={SET_PASSWORD} element={<SetPassword />} />
        <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route path={VERIFY_PHONE} element={<VerifyPhone />} />
        <Route path={OTP_SCREEN} element={<OtpScreen />} />
      </Route>
      <Route path="/" element={<HalfSplitLayout isVk12School={true} />}>
        <Route
          path={ENTER_UID_VK12}
          element={<EnterUserIdentification isVk12School={true} />}
        />
        <Route
          path={VK12_LOGIN_PASSWORD}
          element={<LoginNew isVk12School={true} />}
        />
      </Route>
      <Route path={LOGIN_UID_PASSWORD} element={<LoginUidPass />} />
      <Route path={MCB_LOGIN} element={<MCBLogin />} />
      <Route path={MCB_APP_LOGIN} element={<MCBAppLogin />} />
      <Route path={CHOOSE_PROFILE} element={<ChooseProfile />} />
      <Route path={DEMO_QUESTION} element={<DemoQuestion />} />
      <Route path={DEMO_RESOURCE_CARD} element={<DemoResourceCard />} />

      <Route path="/" element={<AppLayout />}>
        <Route element={<PrivateRouterWrapper />}>
          <Route path="/" element={<Navigate to={HOME} replace />} />
          <Route path={DOCS} element={<Documentation />} />
          <Route path={HOME} element={<Home />} />
          <Route path={SEE_ALL_LESSON_PLAN} element={<SeeAllLessonPlan />} />

          {/* -------------------- Homework -------------------- */}
          <Route
            path={HOMEWORK_STUDENT_DASHBOARD}
            element={<StudentHwDash />}
          />
          <Route path={HOMEWORK_ACTIVE} element={<ActiveHw />} />
          <Route path={HOMEWORK_COMPLETED} element={<CompletedHw />} />
          <Route path={HOMEWORK_SUBMITTED} element={<HwSubmitted />} />
          <Route path={HOMEWORK_START_COVER} element={<HwStartCover />} />
          <Route
            path={`${HOMEWORK_VIEW_RECOMMENDATION}/:subject_id/:chapter_id/:topic_id`}
            element={<ViewRecommendations />}
          />

          {/* -------------------- Performance -------------------- */}
          <Route
            path={PERFORMANCE_STUDENT_DASHBOARD}
            element={<PerformanceDashboard />}
          />
          <Route
            path={PERFORMANCE_STUDENT_CHAPTERWISE}
            element={<ChapterScorePage />}
          />

          {/* -------------------- Learn -------------------- */}
          <Route
            path={`${LEARN_DASHBOARD}/:subject_id`}
            element={<LearnDashboard />}
          />
          <Route
            path={`${LEARN_TOPIC_SELECTION}/:subject_id/:chapter_id`}
            element={<LearnTopicSelection />}
          />
          <Route
            path={`${TOPIC_LESSON}/:subject_id/:chapter_id/:topic_id`}
            element={<TopicLesson />}
          />
          <Route
            path={`${LESSON_VIEW}/:subject_id/:chapter_id/:topic_id/:lesson_id`}
            element={<LessonView />}
          />
          <Route path={RESOURCE_VIEW} element={<ResourceView />} />
          {/* -------------------- Offline screens -------------------- */}
          <Route
            path={`${OFFLINE_LEARN_DASHBOARD}/:subject_id`}
            element={<LearnDashboardOffline />}
          />
          <Route
            path={`${OFFLINE_LEARN_TOPIC_SELECTION}/:subject_id/:chapter_id`}
            element={<LearnTopicSelectionOffline />}
          />
          <Route
            path={`${OFFLINE_TOPIC_LESSON}/:subject_id/:chapter_id/:topic_id`}
            element={<TopicLessonOffline />}
          />
          <Route
            path={`${OFFLINE_LESSON_VIEW}/:subject_id/:chapter_id/:topic_id/:lesson_plan_id`}
            element={<LessonViewOffline />}
          />
          <Route
            path={`${OFFLINE_RESOURCE_VIEW}/:subject_id/:chapter_id/:topic_id/:lesson_plan_id/:resource_id`}
            element={<ResourceViewOffline />}
          />
          <Route path={`${DOWNLOAD_SETTINGS}`} element={<DownloadSettings />} />
          {/* -------------------- Sidebar -------------------- */}
          <Route path={PROFILE} element={<Profile />} />
          <Route path={CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route path={PROFILE_OTP_SCREEN} element={<ProfileOtpScreen />} />
          <Route path={DEMO_RESOURCE} element={<DemoResource />} />
        </Route>
        <Route path={`${NOTIFICATION}`} element={<Notifications />} />
        <Route path={`${HELP}`} element={<HelpAndSupport />} />
        <Route
          path={`${FAQ}`}
          element={<FAQElement role={ProfileRolesEnum.PROFILE_ROLE_STUDENT} />}
        />
        <Route path={`${DISCLAIMER}`} element={<StudentDisclaimer />} />
      </Route>
    </Route>
  )
);
