import { IInteractionButtonEventArgs } from '@events/student/eventsCreator/inputArgs';
import { createInteractionButtonEvent } from '@events/student/eventsCreator/interactionButton';
import {
  getLoginEvent,
  getLogoutEvent,
  login,
  logout,
} from '@events/student/eventsCreator/session';
import { addEventRequest, isAiDebuggingEnabled } from '@geneo2-web/shared-ui';
import { Resource_ResourceEnum } from '@protos/content_management/content.db_pb';
import {
  CHANGE_PASSWORD,
  DISCLAIMER,
  DOWNLOAD_SETTINGS,
  FAQ,
  HELP,
  HOME,
  HOMEWORK_ACTIVE,
  HOMEWORK_COMPLETED,
  HOMEWORK_START_COVER,
  HOMEWORK_STUDENT_DASHBOARD,
  HOMEWORK_SUBMITTED,
  HOMEWORK_VIEW_RECOMMENDATION,
  LEARN_DASHBOARD,
  LEARN_TOPIC_SELECTION,
  LESSON_VIEW,
  NOTIFICATION,
  OFFLINE_LEARN_DASHBOARD,
  OFFLINE_LEARN_TOPIC_SELECTION,
  OFFLINE_LESSON_VIEW,
  OFFLINE_RESOURCE_VIEW,
  OFFLINE_TOPIC_LESSON,
  PERFORMANCE_STUDENT_CHAPTERWISE,
  PERFORMANCE_STUDENT_DASHBOARD,
  PROFILE,
  RESOURCE_VIEW,
  SEE_ALL_LESSON_PLAN,
  TOPIC_LESSON,
} from '../../../routeHandling/RoutesNomenclature';

//* --------------- Login Events ---------------
interface loginProps {
  studentId: number;
  sessionId: string;

  classId: number | undefined;
  sectionId: number | undefined;
  schoolId: number | undefined;
  boardId: number | undefined;
  regionId: number | undefined;
  mediumId: number | undefined;
}
export const aiLoginEvent = async (props: loginProps) => {
  const {
    studentId,
    sessionId,
    classId,
    sectionId,
    schoolId,
    boardId,
    regionId,
    mediumId,
  } = props;

  try {
    login({
      studentId: studentId,
      sessionId: sessionId,
      loginTimestamp: new Date(),
    });

    if (classId && sectionId && schoolId && boardId && mediumId) {
      const loginEventData = getLoginEvent({
        classId,
        sectionId,
        schoolId,
        boardId,
        regionId,
        mediumId,
      });

      if (loginEventData.timestamp) {
        await addEventRequest({
          key: loginEventData.timestamp?.toJsonString(),
          value: loginEventData.toJson(),
        });
      }
      // await StudentEventsAPIServiceV1Client.studentEventProcess(loginEventData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Logout Events ---------------
export const aiLogoutEvent = async () => {
  try {
    logout({ logoutTimestamp: new Date() });

    const logoutEvent = getLogoutEvent();

    if (logoutEvent.timestamp) {
      await addEventRequest({
        key: logoutEvent.timestamp?.toJsonString(),
        value: logoutEvent.toJson(),
      });
    }

    // await StudentEventsAPIServiceV1Client.studentEventProcess(logoutEvent);
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Interaction Events ---------------
interface InteractionArgs extends IInteractionButtonEventArgs {
  // url: string;
  // context: string;
  // name: string;
  pathSegments?: string[];
}
export const interactionEvent = async (props: InteractionArgs) => {
  // console.log('props:', props)
  const { context, name, pathSegments } = props;
  let { url } = props;
  if (pathSegments) {
    url = getStudentPathSegmentMapping(pathSegments) || '';
  }
  // console.log('interactionEvent:', { url: url, context: context, name: name });
  else if (!navigator.onLine) {
    url = `Offline_${url}`;
  }

  try {
    const interactionEventData = createInteractionButtonEvent({
      url: url,
      context: context,
      name: name,
      // timestamp: new Date(),
      lessonSesssionId: props.lessonSesssionId,
      resourceSesssionId: props.resourceSesssionId,
      homeworkSesssionId: props.homeworkSesssionId,
    });
    // console.log('interactionEventData:', interactionEventData);

    if (interactionEventData.timestamp) {
      await addEventRequest({
        key: interactionEventData.timestamp?.toJsonString(),
        value: interactionEventData.toJson(),
      });
    }

    // const response = await StudentEventsAPIServiceV1Client.studentEventProcess(
    //   interactionEventData
    // );
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

// function maps each pathSegment to a corresponding string identifier (key of pathMap)
// returns string depending on isOffline / !navigator.onLine
export function getStudentPathSegmentMapping(pathSegments: string[]) {
  const removeOfflinePrefix = (path: string) => {
    return path.replace('/offline/', '');
  };

  const pathMap = {
    //& ------------------------- HOME -------------------------
    [HOME.slice(1)]: 'Student_Home',
    [SEE_ALL_LESSON_PLAN.slice(1)]: 'Student_See_All_Lesson_Plan',

    //& ------------------------- LEARN -------------------------
    [LEARN_DASHBOARD.slice(1)]: 'Student_Learn_dashboard',
    [removeOfflinePrefix(OFFLINE_LEARN_DASHBOARD)]: 'Student_Learn_dashboard',

    [LEARN_TOPIC_SELECTION.slice(1)]: 'Student_Learn_topic',
    [removeOfflinePrefix(OFFLINE_LEARN_TOPIC_SELECTION)]: 'Student_Learn_topic',

    [TOPIC_LESSON.slice(1)]: 'Student_topic_lesson',
    [removeOfflinePrefix(OFFLINE_TOPIC_LESSON)]: 'Student_topic_lesson',

    [LESSON_VIEW.slice(1)]: 'Student_lesson_view',
    [removeOfflinePrefix(OFFLINE_LESSON_VIEW)]: 'Student_lesson_view',

    [RESOURCE_VIEW.slice(1)]: 'Student_resource_view',
    [removeOfflinePrefix(OFFLINE_RESOURCE_VIEW)]: 'Student_resource_view',

    //& ------------------------- HOMEWORK -------------------------
    [HOMEWORK_STUDENT_DASHBOARD.slice(1)]: 'Student_Homework_dashboard',
    [HOMEWORK_ACTIVE.slice(1)]: 'Student_Homework_active',
    [HOMEWORK_COMPLETED.slice(1)]: 'Student_completed_homework',
    [HOMEWORK_SUBMITTED.slice(1)]: 'Student_homework_submitted',
    [HOMEWORK_START_COVER.slice(1)]: 'Student_homework_start_cover',
    [HOMEWORK_VIEW_RECOMMENDATION.slice(1)]:
      'student_homework_view_recommendation',

    //& ------------------------- PERFORMANCE -------------------------
    [PERFORMANCE_STUDENT_DASHBOARD.slice(1)]: 'Student_performance_dash',
    [PERFORMANCE_STUDENT_CHAPTERWISE.slice(1)]:
      'Student_performance_chapterwise',

    //& ------------------------- SIDEBAR -------------------------
    [PROFILE.slice(1)]: 'Student_profile',
    [CHANGE_PASSWORD.slice(1)]: 'Student_change_password',
    [NOTIFICATION.slice(1)]: 'Student_notification',
    [HELP.slice(1)]: 'Student_help',
    [FAQ.slice(1)]: 'Student_FAQ',
    [DISCLAIMER.slice(1)]: 'Student_disclaimer',
    [DOWNLOAD_SETTINGS.slice(1)]: 'Student_download_settings',
  };

  for (const segment of pathSegments) {
    if (pathMap[segment]) {
      if (!navigator.onLine) return `Offline_${pathMap[segment]}`;
      else return pathMap[segment];
    }
  }
}

const resourceTypeMap: { [key: number]: string } = {
  0: 'RESOURCE_TYPE_UNDEFINED',
  1: 'RESOURCE_TYPE_VIDEO',
  2: 'RESOURCE_TYPE_AUDIO',
  3: 'RESOURCE_TYPE_READING',
  4: 'RESOURCE_TYPE_QUESTION_SET',
  5: 'RESOURCE_TYPE_QUESTION',
  6: 'RESOURCE_TYPE_PRACTICE',
  7: 'RESOURCE_TYPE_TEST',
  8: 'RESOURCE_TYPE_ACTIVITY',
  9: 'RESOURCE_TYPE_SIMULATION',
  10: 'RESOURCE_TYPE_EXPLORIMENT',
  11: 'RESOURCE_TYPE_GAME',
  12: 'RESOURCE_TYPE_AR_VR',
  13: 'RESOURCE_TYPE_PPT',
  14: 'RESOURCE_TYPE_E_BOOK',
  15: 'RESOURCE_TYPE_PDF',
  16: 'RESOURCE_TYPE_FLASHCARDS',
  17: 'RESOURCE_TYPE_MINDMAP',
};

export const getResourceType = (number: number): string => {
  // eslint-disable-next-line no-debugger
  return Resource_ResourceEnum[number] || Resource_ResourceEnum[0];
};

/*
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  await interactionEvent({
    url: '',
    context: 'context',
    name: 'name',
    pathSegments: pathSegments,
  });

 */
