import { Timestamp } from '@bufbuild/protobuf';
import {
  ActionPopUpSingleButton,
  ChapterSelectedCard,
  ContentDetailCard,
  HwProceedButton,
  IStyles,
  InfoBar,
  Loader,
  LockToggleButton,
  NoContentCard,
  PrimaryButton,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  getMediaBasePath,
  getResourceCategory,
  getStudentSubjectEnum,
  pxToRem,
  pxTovW,
  resourceTypeName,
  theme,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  SessionModeEnum,
  SessionStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { getSubjectsMap } from '../../../utils/icons';
import { onResourceClick } from '../../../utils/learn';
import {
  setLessonPlanRedirectionPath,
  setLessonPreviousSessionInfo,
  setSelectedLessonContent,
  setUpdatedLessonSessionVisitedResourceInfo,
} from '../reducer/learn.slice';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  ChapterMetaInfo,
  DownloadedSubject,
  Module_ModuleCategoryEnum,
  ResourceCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { ResourceSelectionFromTypeEnum } from '@protos/geneo_ai/ai.student.events_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { Feature } from '@protos/school_management/school.db_pb';
import { StudentLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { DownloadButtonWrapper } from 'apps/student/src/components/DownloadButtonWrapper';
import { interactionEvent } from '../../Auth/Login/login_logout.events';
import {
  aiLessonCloseEvent,
  aiLessonOpenEvent,
  aiResourceOpenEvent,
} from '../utils_learn/learn.events';
import { HOME } from 'apps/student/src/routeHandling/RoutesNomenclature';
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

const styles: IStyles = {
  root: {
    width: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    paddingY: { xs: pxToRem(20), md: pxTovW(40) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
    paddingX: { xs: pxToRem(20), lg: 0 },

    p: { xs: pxToRem(20), lg: `${pxTovW(30)} ${pxTovW(240)} ${pxTovW(100)}` },
  },
  header: {
    maxWidth: {
      xs: '100%',
      lg: 'unset',
    },
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
      // backgroundColor: 'red',
    },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(17) },
    justifyContent: {
      xs: 'flex-start',
      md: 'space-between',
    },
    margin: '0 auto',
    alignItems: { md: 'center' },
  },
  bodySection: {
    display: 'flex',
    gap: { xs: 0, md: pxTovW(60) },
    flexDirection: { xs: 'column', md: 'row' },
    maxWidth: {
      xs: '100%',
      md: 'unset',
    },
    margin: '0 auto',
    justifyContent: { xs: 'flex-start', md: 'space-between' },
  },
  countBox: {
    height: { xs: pxToRem(79), md: pxTovW(141) },
    width: '100%',
    borderRadius: pxToRem(10),
    backgroundColor: '#FFFFFF',
    border: '1px dashed #BEB8FD',
    display: { xs: 'none', md: 'flex' },
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: pxToRem(10),
    boxSizing: 'border-box',
    boxShadow: `0px 3px ${pxToRem(35)} #0000000F`,
    padding: { xs: pxToRem(20), md: pxTovW(40) },
  },

  //
  rightPanel: {
    // marginLeft: { md: 'auto' },
    // paddingX: pxTovW(20),
    width: { md: '55%', lg: pxTovW(727) },
    // flexBasis: { md: pxTovW(727) },
    // flexGrow: 1,
    // backgroundColor: 'red',
  },
  infoBox: {},
  editButton: {
    cursor: 'pointer',
    padding: 0,
    minWidth: 0,
    width: { xs: pxToRem(34), md: pxTovW(50) },
    height: { xs: pxToRem(34), md: pxTovW(50) },
    borderRadius: pxTovW(8),
    bgcolor: 'common.white',
    border: '1px solid #EEEEEE',
  },
  cardsContainer: {
    width: { md: '100%', lg: pxTovW(727) },
    paddingTop: pxTovW(20),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: { xs: pxToRem(60), md: pxTovW(10) },
    gap: { xs: pxToRem(20), md: pxTovW(20) },
    // backgroundColor: 'blue',
  },
};

export const LessonView = () => {
  const [contentLoading, setContentLoading] = useState<
    'loading' | 'completed' | 'error' | 'invalid session'
  >('completed');
  const { isIpadOnly } = useGlobalContext();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { subject_id, lesson_id, topic_id, chapter_id } = useParams();
  const lessonId = lesson_id;
  const subjectId = Number(subject_id);
  const topicId = Number(topic_id);
  const chapterId = Number(chapter_id);

  const dispatch = useDispatch();
  const userInfo = deserify(useAppSelector((state) => state.auth.userInfo));
  const featureInfo = userInfo?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const previousLessonSessionInfo = deserify(
    useAppSelector((state) => state.learn.previous_lesson_session_info)
  );
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonLearnAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  const navigate = useNavigate();
  const { setSelectedFunction, setSelectedLogoClickHandler } =
    useGlobalContext();
  const redirectionPath = useAppSelector(
    (state) => state.learn.lesson_plan_redirection_path
  );

  const location = useLocation();
  const lesson_session_id =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;

  const [fetchingState, setFetchingState] = useState(false);
  const [lockedData, setLockedData] = useState<ContentLockModuleData>();
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });
  const [actionPopupState, setActionPopupState] = useState(false);
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});

  const backButtonClick = async (navigateType = 'default') => {
    await aiLessonCloseEvent(lesson_id);

    if (redirectionPath) {
      const completedIds =
        previousLessonSessionInfo?.completedResourceIds || [];
      if (lesson_session_id && lesson_id && lessonContent) {
        LessonLearnAPIServiceV1ClientWithStatusCodeHandler.updateStudentLessonSession(
          {
            studentId: userInfo?.studentProfileId,
            studentLessonSessionId: Number(lesson_session_id),
            lessonId: lesson_id,
            sessionStatus:
              completedIds.length < lessonContent?.resources.length
                ? SessionStatusEnum.SESSION_STATUS_EXITED
                : SessionStatusEnum.SESSION_STATUS_COMPLETED,
            endTime: Timestamp.fromDate(new Date()),
          }
        );
      }
      dispatch(setLessonPlanRedirectionPath(undefined));
      dispatch(setUpdatedLessonSessionVisitedResourceInfo({}));
      if (navigateType === 'logo') navigate(HOME);
      else navigate(redirectionPath);
    } else {
      if (navigateType === 'logo') {
        navigate(HOME);
      } else navigate(-1);
    }
  };

  const aiEvents = async () => {
    // // if book id missing -> (getBookId)
    // let searchedBookId = selected_book_id;
    // if (!searchedBookId) {
    //   searchedBookId = getBookId(userInfo, subjectChapterInfo?.subjectId);
    //   dispatch(setHomeSelectedBookId(searchedBookId));
    // }
    // await aiBookOpenEvent(searchedBookId);

    // await aiChapterOpenEvent(selected_chapter_resources?.chapterId);

    // // when coming to lesson directly topicId(moduleId) will not be updated in redux that is needed to close/clear event
    // const tempTopicData = selected_module_lessons;
    // if (tempTopicData) tempTopicData.moduleId = Number(topic_id);
    // dispatch(setSelectedModuleLessons(tempTopicData));
    // await aiTopicOpenEvent(Number(topic_id));

    await aiLessonOpenEvent(lesson_id);
  };
  useEffect(() => {
    setSelectedFunction(() => () => backButtonClick('default'));
    setSelectedLogoClickHandler(() => () => backButtonClick('logo'));
    aiEvents();
    return () => {
      setSelectedFunction(null);
      setSelectedLogoClickHandler(null);
    };
  }, [lesson_session_id]);
  // }, [previousLessonSessionInfo, lesson_session_id]);

  const lessonContent = deserify(
    useAppSelector((state) => state.learn.selected_lesson_content)
  );
  const estimatedTime =
    lessonContent?.resources.reduce(
      (acc, lc) => acc + lc.estimatedTimeInMin,
      0
    ) || 0;

  const completedResourceIds =
    previousLessonSessionInfo?.completedResourceIds || [];
  const learnSubjects = userInfo?.learnSubjects || [];
  const subMap = getSubjectsMap(learnSubjects);
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );

  const onLearnClicked = async (resourceId?: string) => {
    const initialResourceId = resourceId; // to check if Learn was clicked or Resource

    if (!resourceId) {
      const allResourceIds =
        lessonContent?.resources.map((res) => res.resourceId) || [];
      if (!allResourceIds.length) {
        return;
      }
      const notCompletedResourceIds = allResourceIds.filter(
        (resId) => !completedResourceIds.includes(resId)
      );
      resourceId = notCompletedResourceIds[0] || allResourceIds[0];
      if (!resourceId) {
        return;
      }
    }
    let lessonSessionId = lesson_session_id
      ? Number(lesson_session_id)
      : undefined;
    if (!lesson_session_id) {
      const newSessionInfo =
        await LessonLearnAPIServiceV1ClientWithStatusCodeHandler.createStudentLessonSession(
          {
            studentId: userInfo?.studentProfileId,
            lessonId: lessonId,
            startTime: Timestamp.fromDate(new Date()),
            sessionResourceIds:
              previousLessonSessionInfo?.completedResourceIds || [],
            schoolId: userInfo?.schoolDetails?.schoolId,
            academicYear: 0,
            classId: userInfo?.classSectionDetails?.classId,
            section: userInfo?.classSectionDetails?.sectionName,
            subject:
              subjectId && !isNaN(Number(subjectId))
                ? getStudentSubjectEnum(
                    Number(subjectId),
                    userInfo?.learnSubjects
                  )
                : undefined,
            sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
            sessionMode: SessionModeEnum.SESSION_MODE_LEARN,
          }
        );
      if (newSessionInfo.studentLessonSessionId) {
        lessonSessionId = newSessionInfo.studentLessonSessionId;
        dispatch(setUpdatedLessonSessionVisitedResourceInfo({}));
      }
    }

    onResourceClick(
      navigate,
      {
        resourceId,
        lessonId,
        lessonSessionId,
        subjectId,
        topicId,
        chapterId,
      },
      SessionModeEnum.SESSION_MODE_LESSON_RESOURCE
    );

    if (initialResourceId) {
      await interactionEvent({
        url: 'Student_lesson_view',
        context: 'lesson_flow',
        name: 'OPEN',
        lessonSesssionId: lessonSessionId?.toString(),
      });
      await aiResourceOpenEvent(
        resourceId,
        ResourceSelectionFromTypeEnum.LESSON_PLAN_HOMEPAGE
      );
    } else {
      await interactionEvent({
        url: 'Student_lesson_view',
        context: 'navigation_bar',
        name: 'LEARN',
        lessonSesssionId: lessonSessionId?.toString(),
      });
    }
  };

  useEffect(() => {
    fetchLessonContent();

    (async () => {
      if (!subject_id || !chapter_id || !topic_id || !lesson_id) {
        return;
      }
      setFetchingState(true);
      const indexDbData = await findDownloadedSubjectByParams({
        subjectId: Number(subject_id),
      });
      const meta = deepClone(downloadedMetaData);
      if (indexDbData.length > 0) {
        const data = indexDbData[0];
        const offlineData =
          await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
            {
              chapterId: Number(chapter_id),
              topicId: Number(topic_id),
              lessonplanId: lesson_id,
            }
          );
        meta[Number(chapter_id)] = offlineData.data;
      }
      setDownloadedMetaData(meta);
      setFetchingState(false);
    })();
  }, []);

  const fetchLockContent = async () => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.studentModuleLockInfoFetch(
          {
            subjectId: subjectId,
            studentId: userInfo?.studentProfileId,
            moduleId: topicId,
            category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
          }
        );
      if (response?.data) {
        const data = response.data;
        console.log(data, 'lockData');
        setLockedData(data);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // useEffect(() => {
  //   if (subject_id && !isNaN(Number(subject_id))) {
  //     (async () => {
  //       const indexDbData = await findDownloadedSubjectByParams({
  //         subjectId: Number(subject_id),
  //       });
  //       if (indexDbData.length > 0) {
  //         const data = indexDbData[0];
  //         setDownloadedSubject(data);
  //       }
  //     })();
  //   }
  // }, [currentDownload]);
  console.log(topicId, 'chapterId');
  const lockedResource =
    lockedData?.moduleUpdatedLockInfo?.lessonsLockInfo.find(
      (val) => val.lessonId === lessonId
    );

  console.log(lockedResource, 'lockedResource');

  const fetchLessonContent = async () => {
    try {
      setLoading('loading');
      setContentLoading('loading');
      const lessonContent =
        await LessonCommonAPIServiceV1ClientWithStatusCodeHandler.fetchLessonContent(
          {
            personId: userInfo?.studentProfileId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            lessonId: lessonId,
          }
        );
      const prevLessonSessionInfo =
        await LessonLearnAPIServiceV1ClientWithStatusCodeHandler.getPreviousLessonSessionInfo(
          {
            studentId: userInfo?.studentProfileId,
            lessonId: lessonId,
          }
        );
      if (lessonContent) {
        if (isContentLockFeature) {
          await fetchLockContent();
        }
        if (lessonContent.data) {
          dispatch(setSelectedLessonContent(lessonContent.data));
        } else {
          dispatch(setSelectedLessonContent(undefined));
        }
        setContentLoading('completed');
      } else {
        dispatch(setSelectedLessonContent(undefined));
        setContentLoading('error');
      }
      dispatch(setLessonPreviousSessionInfo(prevLessonSessionInfo.data));
    } catch (err) {
      console.log('error', err);
      setLoading('error');

      dispatch(setSelectedLessonContent(undefined));
      dispatch(setLessonPreviousSessionInfo(undefined));
    }
  };

  const lessonTabs = [
    {
      quantity: (lessonContent?.resources.length || 0).toString(),
      title: 'Resources',
    },
    { quantity: estimatedTime.toString(), title: 'Mins' },
  ];

  const popup = () => {
    setPopupDetails({
      iconName: 'locked@2x',
      buttontext: 'ok',
      popupText:
        'Your school has locked this resource. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };

  // const isLocked =
  //   lockedResource &&
  //   lockedResource?.lockedResourceIds?.includes(resource.resourceId);

  return contentLoading === 'loading' ? (
    // <Typography variant="h2">Loading ...</Typography>
    <Loader />
  ) : contentLoading === 'error' ? (
    <NoContentCard variant="error" icon="error" text="Error Occured" />
  ) : contentLoading === 'invalid session' ? (
    <NoContentCard variant="error" icon="error" text="Invalid Session" />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Box
          sx={{
            width: { xs: '100%', lg: pxTovW(960) },
            wordBreak: 'break-word',
          }}
        >
          <ChapterSelectedCard
            image={getMediaBasePath(
              lessonContent?.lessonPosterImageUrl,
              'processedMediaBucket'
            )}
            chapterName={lessonContent?.lessonTitle || ''}
            subject={subjectId ? subMap[subjectId]?.subjectName || '' : ''}
            headingRightElement={
              !fetchingState && (
                <Box
                  sx={{
                    marginLeft: { xs: 'auto', md: '2px' },
                    marginRight: isIpadOnly ? pxToRem(10) : '0px',
                  }}
                >
                  <DownloadButtonWrapper
                    user={new StudentLoginResponseType(userInfo)}
                    downloadedSubject={
                      downloadedSubject
                        ? new DownloadedSubject(downloadedSubject)
                        : undefined
                    }
                    downloadedMetaData={downloadedMetaData}
                    setDownloadedMetaData={setDownloadedMetaData}
                    subjectId={subjectId}
                    classId={userInfo?.classSectionDetails?.classId}
                    sectionId={userInfo?.classSectionDetails?.sectionId}
                    chapterId={chapterId}
                    topicId={topicId}
                    lessonPlanId={lesson_id}
                    title={`Lesson Plan - ${lessonContent?.lessonTitle}`}
                    interactionEventUrl={'Student_lesson_view'}
                    interactionEventContext={'ongoing_lesson'}
                    lockedData={lockedData}
                  />
                </Box>
              )
            }
          />
        </Box>

        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            height: 'max-content',
            width: { md: pxTovW(444) },
            flexShrink: 0,
          }}
        >
          <PrimaryButton
            onClick={async () => {
              // await interactionEvent({
              //   url: 'Student_lesson_view',
              //   context: 'navigation_bar',
              //   name: 'LEARN',
              // });
              onLearnClicked(undefined);
            }}
            sx={{
              boxShadow: `inset 0 0 ${pxTovW(8)} #00602B , 0 0 ${pxTovW(
                7
              )} #0AA34F63`,
              height: { xs: pxToRem(52), md: pxTovW(70) },
            }}
            fullWidth
          >
            <Typography
              variant="bodyText"
              fontWeight="bold"
              color={'white'}
              sx={{ letterSpacing: '1px' }}
            >
              LEARN
            </Typography>
          </PrimaryButton>
        </Box>
      </Box>

      <Box sx={styles.bodySection}>
        <Box
          sx={{
            width: { xs: '100%', md: '45%', lg: pxTovW(444) },
            flexBasis: { md: '45%', lg: pxTovW(442) },
            // backgroundColor: 'blue',
            justifyContent: 'center',
          }}
        >
          {/* {renderCountBox()} */}
          {largeScreen && (
            <InfoBar
              contentList={[
                {
                  iconName: 'questions',
                  heading: (lessonContent?.resources.length || 0).toString(),
                  subHeading: 'resources',
                },
                {
                  iconName: 'clock',
                  heading: estimatedTime.toString(),
                  subHeading: 'Minutes',
                },
              ]}
            />
          )}
          <Box
            sx={{
              marginTop: { md: pxTovW(20) },
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
              marginX: 'auto',
              width: { md: pxTovW(444), lg: '100%' },
            }}
          >
            <PrimaryButton
              onClick={async () => {
                // await interactionEvent({
                //   url: 'Student_lesson_view',
                //   context: 'navigation_bar',
                //   name: 'LEARN',
                // });
                onLearnClicked(undefined);
              }}
              fullWidth
              sx={{
                boxShadow: `inset 0 0 ${pxTovW(8)} #00602B , 0 0 ${pxTovW(
                  7
                )} #0AA34F63`,
                height: { xs: pxToRem(52), md: pxTovW(70) },
              }}
            >
              <Typography
                variant="bodyText"
                fontWeight="bold"
                color={'white'}
                sx={{ letterSpacing: '1px' }}
              >
                LEARN
              </Typography>
            </PrimaryButton>
          </Box>
        </Box>

        <Box sx={styles.rightPanel}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h2">Lesson Flow</Typography>
          </Box>
          {lessonContent && lessonContent.resources.length !== 0 ? (
            <Box sx={styles.cardsContainer}>
              {lessonContent?.resources.map((e, i) => (
                <ContentDetailCard
                  isLocked={
                    isContentLockFeature &&
                    lockedResource &&
                    lockedResource?.lockedResourceIds?.includes(e.resourceId)
                  }
                  locked={
                    isContentLockFeature && (
                      <LockToggleButton
                        isLocked={
                          lockedResource &&
                          lockedResource?.lockedResourceIds?.includes(
                            e.resourceId
                          )
                        }
                      />
                    )
                  }
                  key={i}
                  variant="large"
                  tagName={getResourceCategory(e.resourceCategoryType)}
                  image={getMediaBasePath(
                    e.posterImageUrl,
                    e.resourceCategoryType ===
                      ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                      ? 'userDataBucket'
                      : 'processedMediaBucket'
                  )}
                  heading={e.title}
                  onClick={async () => {
                    // await interactionEvent({
                    //   url: 'Student_lesson_view',
                    //   context: 'lesson_flow',
                    //   name: 'OPEN',
                    // });
                    // await aiResourceOpenEvent(
                    //   e.resourceId,
                    //   ResourceSelectionFromTypeEnum.LESSON_PLAN_HOMEPAGE
                    // );

                    if (
                      isContentLockFeature &&
                      lockedResource &&
                      lockedResource?.lockedResourceIds?.includes(e.resourceId)
                    ) {
                      popup();
                      return;
                    }

                    onLearnClicked(e.resourceId);
                  }}
                  showCompletedRibbon={completedResourceIds.includes(
                    e.resourceId
                  )}
                  iconDetails={[
                    {
                      iconName: 'clock',
                      text: e.estimatedTimeInMin + ' Min',
                    },
                    {
                      iconName: resourceTypeName(e.resourceType)?.icon,
                      text: resourceTypeName(e.resourceType)?.name,
                    },
                  ]}
                  showVideoIcon={
                    e.resourceType === Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                  }
                  rootStyle={{
                    width: '100%',
                    backgroundColor: 'common.white',
                  }}
                />
              ))}
            </Box>
          ) : (
            <NoContentCard
              variant="info"
              icon="cards"
              text="No Content Available"
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          position: 'fixed',
          bottom: 10,
        }}
        onClick={() => onLearnClicked(undefined)}
      >
        <HwProceedButton buttonTitle="LEARN" tabs={lessonTabs} />
      </Box>

      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </Box>
  );
};

const cdcIconDetails = [
  {
    iconName: 'clock',
    text: '15 Min',
  },
  {
    iconName: 'questions',
    text: 'Reading',
  },
];
