import {
  ContentDetailCard,
  IStyles,
  deserify,
  getMediaBasePath,
  getResourceCategory,
  pxToRem,
  pxTovW,
  resourceTypeName,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Button, Typography } from '@mui/material';

import { SessionModeEnum } from '@protos/learning_management/lms.db_pb';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DEMO_RESOURCE } from '../../routeHandling/RoutesNomenclature';
import { onResourceClick } from '../../utils/learn';
import { setResourceRedirectionPath } from '../Learn/reducer/learn.slice';
import { useAppSelector } from '../../reduxStore/reduxHooks';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ResourceContent } from '@protos/content_management/content.common.apis_pb';
import { Resource_ResourceEnum } from '@protos/content_management/content.db_pb';

const styles: IStyles = {
  paginationButtonBox: {
    position: 'fixed',
    bottom: { md: pxTovW(30), xs: pxToRem(10) },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  iconTextBoxContainer: {
    width: '100%',
    display: 'flex',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    alignItems: 'center',
    mt: { xs: pxToRem(10), md: pxTovW(10) },
  },
  headingImage: {
    width: { xs: pxToRem(60), md: pxTovW(105) },
    height: { xs: pxToRem(60), md: pxTovW(105) },
  },
  resourcePage: {
    paddingBottom: {
      xs: pxToRem(75),
      md: pxTovW(110),
    },
  },
};

interface IProps {
  headerButtonText?: string;
  buttonClickHandler?: () => void;
}
export default function DemoResource(props: IProps) {
  const [resourceId, setResourceId] = useState('');
  const [resourceInfo, setResourceInfo] = useState<
    ResourceContent | undefined
  >();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );

  const userInfo = deserify(useAppSelector((state) => state.auth.userInfo));
  const studentId = userInfo?.studentProfileId;
  const { ContentCommonAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const fetchResourceData = async (resourceId: string) => {
    try {
      setLoading('loading');
      const res =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchResourceContent(
          {
            personId: studentId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            resourceId: resourceId,
          }
        );
      if (res?.data) {
        const resource = new ResourceContent(res.data);
        setResourceInfo(resource);
      }
      setLoading('completed');
    } catch (err) {
      setLoading('error');
    }
  };
  const clickHandler = () => {
    dispatch(setResourceRedirectionPath(DEMO_RESOURCE));
    onResourceClick(
      navigate,
      {
        resourceId: resourceId,
        demoResource: true,
      },
      SessionModeEnum.SESSION_MODE_UNDEFINED
    );
  };
  if (loading === 'loading') {
    return (
      <Typography variant="h2" sx={{ textAlign: 'center', margin: '20px' }}>
        Loading...
      </Typography>
    );
  }
  return (
    <Box sx={{ margin: '20px' }}>
      URL:
      <Box
        component={'input'}
        type="text"
        value={resourceId}
        onChange={(ev) => setResourceId(ev.target.value)}
        sx={{
          width: '100%',
        }}
      />
      {/* <Box sx={{ color: 'red', mY: '10px' }}>{errorMsg}</Box> */}
      <br />
      <br />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          fetchResourceData(resourceId);
        }}
      >
        Submit
      </Button>
      <Box sx={{ margin: '20px' }}>
        {loading === 'error' ? (
          <Typography variant="h2">
            Something went wrong! Please try again or use another resource ID
          </Typography>
        ) : resourceInfo ? (
          <Box width={'100%'}>
            <Typography
              variant="h3"
              sx={{
                width: '100%',
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
              }}
            >
              <b>Resource Id:</b> {resourceInfo.resourceId}
            </Typography>
            <br />
            <br />
            <ContentDetailCard
              variant="large"
              tagName={getResourceCategory(resourceInfo.resourceCategory)}
              image={getMediaBasePath(
                resourceInfo.posterImageUrl,
                'processedMediaBucket'
              )}
              heading={resourceInfo.title}
              onClick={clickHandler}
              iconDetails={[
                {
                  iconName: 'clock',
                  text: resourceInfo.estimatedTimeInMin + ' Min',
                },
                {
                  iconName: resourceTypeName(resourceInfo.resourceType)?.icon,
                  text: resourceTypeName(resourceInfo.resourceType)?.name,
                },
              ]}
              showVideoIcon={
                resourceInfo.resourceType ===
                Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
              }
            />
          </Box>
        ) : (
          <Typography variant="h2">No Resource Found</Typography>
        )}
      </Box>
    </Box>
  );
}
