import { Timestamp } from '@bufbuild/protobuf';
import {
  ContentIdAttempt,
  IStyles,
  IconWrapper,
  ImageWrapper,
  InPageHeader,
  Loader,
  NoContentCard,
  PaginationButton,
  ResourcePageContent,
  deepClone,
  deserify,
  getMediaBasePath,
  getStudentSubjectEnum,
  pxToRem,
  pxTovW,
  resourceTypeName,
  shouldIncludeCredentialsInRequest,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import {
  Content,
  Module_ModuleCategoryEnum,
  PageContentInfo,
  Question,
  ResourceCategoryEnum,
  ResourceContent,
  ResourceTeacherInstruction,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { UpdateStudentResourceSessionRequest } from '@protos/learning_management/lms.common.apis_pb';
import {
  ContentAttempt,
  SessionModeEnum,
  SessionStatusEnum,
  StudentLessonSessionMetrics,
  TeacherContentResponse,
  VisitedResourceContentInfoList,
} from '@protos/learning_management/lms.db_pb';
import { UpdateStudentLessonSessionRequest } from '@protos/learning_management/lms.lesson.learn.apis_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOME,
  LEARN_TOPIC_SELECTION,
  TOPIC_LESSON,
} from '../../../routeHandling/RoutesNomenclature';
import { onLessonCardClick, onResourceClick } from '../../../utils/learn';
import {
  setResourceRedirectionPath,
  setSelectedResourceContent,
  setSelectedResourceInfo,
  setSelectedResourceSessionId,
  setUpdatedLessonSessionVisitedResourceInfo,
} from '../reducer/learn.slice';

import { interactionEvent } from '../../Auth/Login/login_logout.events';
import {
  aiContentCloseEvent,
  aiContentOpenEvent,
  aiContentQuestionSubmitEvent,
  aiResourceCloseEvent,
  aiResourceOpenEvent,
  aiResourcePageCloseEvent,
  aiResourcePageOpenEvent,
} from '../utils_learn/learn.events';
import useFetchLockedResourceIds, {
  ILockedInfoParams,
} from './resourceLockFetch';
import { Feature } from '@protos/school_management/school.db_pb';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';

const styles: IStyles = {
  paginationButtonBox: {
    position: 'fixed',
    bottom: { md: pxTovW(30), xs: pxToRem(10) },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  iconTextBoxContainer: {
    width: '100%',
    display: 'flex',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    alignItems: 'center',
    mt: { xs: pxToRem(10), md: pxTovW(10) },
  },
  headingImage: {
    width: { xs: pxToRem(60), md: pxToRem(105) },
    height: { xs: pxToRem(60), md: pxToRem(105) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    objectFit: 'cover',
  },
  resourcePage: {
    paddingBottom: {
      xs: pxToRem(75),
      md: pxTovW(110),
    },
  },
};

interface IProps {
  headerButtonText?: string;
  buttonClickHandler?: () => void;
}
export default function ResourceView(props: IProps) {
  const navigate = useNavigate();
  const userInfo = deserify(useAppSelector((state) => state.auth.userInfo));
  const studentId = userInfo?.studentProfileId;
  const activeResourceContent = deserify(
    useAppSelector((state) => state.learn.selected_resource_content)
  )?.parsedData;
  const rawResourceContent = deserify(
    useAppSelector((state) => state.learn.selected_resource_content)
  )?.rawData;
  const activeResourceSessionId = deserify(
    useAppSelector((state) => state.learn.selected_resource_session_id)
  );
  const selectedLessonContent = deserify(
    useAppSelector((state) => state.learn.selected_lesson_content)
  );
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonLearnAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const [completedResourceIds, setCompletedResourceIds] = useState<string[]>(
    []
  );
  const { setSelectedFunction, setSelectedLogoClickHandler } =
    useGlobalContext();

  const dispatch = useDispatch();

  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const subjectId =
    new URLSearchParams(location.search).get('subjectId') || undefined;
  const resourceId =
    new URLSearchParams(location.search).get('resourceId') || undefined;
  const lessonId =
    new URLSearchParams(location.search).get('lessonId') || undefined;
  const lessonSessionId =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;
  const chapterId =
    new URLSearchParams(location.search).get('chapterId') || undefined;
  const topicId =
    new URLSearchParams(location.search).get('topicId') || undefined;
  const session_mode =
    new URLSearchParams(location.search).get('sessionMode') || undefined;
  const demoResource =
    new URLSearchParams(location.search).get('demoResource') || undefined;
  const sessionMode = session_mode
    ? (Number(session_mode) as SessionModeEnum)
    : undefined;

  const isContentLockFeature = useFeatureEnabled(
    userInfo?.schoolDetails?.featuresPlanInfo,
    Feature.CONTENT_LOCK
  );
  const params: ILockedInfoParams = {
    studentProfileId: studentId,
    subjectId: Number(subjectId),
    category: topicId
      ? Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC
      : Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER,
    moduleId: topicId ? Number(topicId) : Number(chapterId),
    lessonId: lessonId,
    isFeatureAvailable: isContentLockFeature,
  };
  const {
    lockedResourceIds,
    loading: lockedLoading,
    error: lockedError,
  } = useFetchLockedResourceIds(params);

  console.log(
    'useFetchLockedResourceIds',
    lockedResourceIds,
    lockedLoading,
    lockedError
  );
  const lesson_session_visited_resource_info =
    deserify(
      useAppSelector(
        (state) => state.learn.lesson_session_visited_resource_info
      )
    ) || {};
  const visitedResourceInfo =
    (lessonSessionId
      ? lesson_session_visited_resource_info[lessonSessionId]
      : []) || [];
  const resourceRedirectionPath = useAppSelector(
    (state) => state.learn.resource_redirection_path
  );
  const pageContents = (
    new ResourceContent(activeResourceContent)?.resourceContentData
      ?.pageContent || []
  ).sort((a, b) => a.pageNumber - b.pageNumber);
  const [pageNumber, setPageNumber] = useState(0);
  const [contentIdAttempt, updateContentIdAttempt] = useState<ContentIdAttempt>(
    {}
  );

  const pageContent = pageContents[pageNumber];
  const [startTime, setStartTime] = useState(Timestamp.fromDate(new Date()));
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );

  const { selected_resource_info } = deserify(
    useAppSelector((state) => state.learn)
  );
  useEffect(() => {
    fetchResourceData();
    updateContentIdAttempt({});
  }, [resourceId]);

  useEffect(() => {
    console.log('lockedResourceIds', lockedResourceIds, resourceId);
    if (resourceId && lockedResourceIds.includes(resourceId)) {
      skipResourceIfLocked();
    }
  }, [lockedResourceIds, resourceId]);

  const aiCloseEvents = async () => {
    await aiContentCloseEvent({
      pageNumber: selected_resource_info?.pageNumber,
      pageContent: selected_resource_info?.pageContent || [],
      answer: selected_resource_info?.currentAnswer,
    });
    await aiResourcePageCloseEvent(
      resourceId,
      selected_resource_info?.pageNumber
    );
    await aiResourceCloseEvent(resourceId);
  };
  useEffect(() => {
    //~ Back Arrow
    const handleExit = async (navigateHome: boolean) => {
      await aiCloseEvents();
      await handleResourceCompleteOrEnd(
        SessionStatusEnum.SESSION_STATUS_EXITED
      );
      if (navigateHome) {
        navigate(HOME);
      }
    };
    setSelectedFunction(() => handleExit);
    setSelectedLogoClickHandler(() => () => handleExit(true));
    return () => {
      setSelectedFunction(null);
      setSelectedLogoClickHandler(null);
    };
  }, [
    activeResourceSessionId,
    resourceId,
    contentIdAttempt,
    pageNumber,
    completedResourceIds,
  ]);

  useEffect(() => {
    setStartTime(Timestamp.fromDate(new Date()));
  }, [pageNumber, resourceId]);

  const fetchResourceData = async () => {
    const newPageNumber = 0;
    setPageNumber(newPageNumber);
    if (resourceId) {
      try {
        setLoading('loading');
        const resourceContent =
          await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchResourceContent(
            {
              personId: studentId,
              personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
              resourceId: resourceId,
            }
          );
        const gcpJsonURL = resourceContent.data?.gcpJsonUrl;
        if (!gcpJsonURL) {
          throw Error('Error while loading resource content');
          return;
        }
        const modifiedUrl = getMediaBasePath(
          gcpJsonURL,
          resourceContent.data?.resourceCategory ===
            ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
            ? 'userDataBucket'
            : 'resourceContentBucket'
        );
        const options: RequestInit = {};
        if (shouldIncludeCredentialsInRequest(modifiedUrl)) {
          options.credentials = 'include';
        }
        const gcpResponse = await (await fetch(modifiedUrl, options)).json();
        const resContent = new ResourceContent().fromJson(gcpResponse, {
          ignoreUnknownFields: true,
        });
        dispatch(
          setSelectedResourceContent({
            rawData: resourceContent.data,
            parsedData: resContent,
          })
        );
        if (demoResource !== 'true') {
          const resourceSession =
            await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.createStudentResourceSession(
              {
                studentId: studentId,
                sessionMode: sessionMode,
                startTime: Timestamp.fromDate(new Date()),
                resourceId: resourceId,
                schoolId: userInfo?.schoolDetails?.schoolId,
                academicYear: 0,
                classId: userInfo?.classSectionDetails?.classId,
                section: userInfo?.classSectionDetails?.sectionName,
                subject:
                  subjectId && !isNaN(Number(subjectId))
                    ? getStudentSubjectEnum(
                        Number(subjectId),
                        userInfo?.learnSubjects
                      )
                    : undefined,
                moduleId: topicId
                  ? Number(topicId)
                  : chapterId
                  ? Number(chapterId)
                  : undefined,
                studentLessonSessionId:
                  lessonSessionId && !isNaN(Number(lessonSessionId))
                    ? Number(lessonSessionId)
                    : undefined,
                sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
              }
            );
          dispatch(
            setSelectedResourceSessionId(
              resourceSession.studentResourceSessionId
            )
          );
          await interactionEvent({
            url: 'Student_resource_view',
            context: 'learn',
            name: 'RESOURCE_OPENED',
            lessonSesssionId: lessonSessionId,
            resourceSesssionId:
              resourceSession.studentResourceSessionId.toString(),
          });
          await aiResourceOpenEvent(resourceId);
          await aiResourcePageOpenEvent(resourceId, newPageNumber + 1);
          await aiContentOpenEvent({
            resourceId: resourceId,
            pageNumber: newPageNumber + 1,
            pageContent: resContent?.resourceContentData?.pageContent || [],
          });
        }
        // openResourceEvents({
        //   resourceId: resourceId,
        //   pageNumber: 1,
        //   pageContent: resContent?.resourceContentData?.pageContent || [],
        // });
        dispatch(
          setSelectedResourceInfo({
            pageNumber: newPageNumber + 1,
            resourceId: resourceId,
            pageContent: (
              resContent?.resourceContentData?.pageContent || []
            ).map((val) => new PageContentInfo(val)),
            resourceResponse: gcpResponse,
            currentAnswer: [],
          })
        );

        console.log('ResourceView');

        if (lessonId) {
          const prevLessonSessionInfo =
            await LessonLearnAPIServiceV1ClientWithStatusCodeHandler.getPreviousLessonSessionInfo(
              {
                studentId: userInfo?.studentProfileId,
                lessonId: lessonId,
              }
            );
          const resIds = prevLessonSessionInfo.data?.completedResourceIds;
          if (resIds && resIds.length > 0) {
            setCompletedResourceIds(resIds);
          } else {
            setCompletedResourceIds([]);
          }
        } else {
          setCompletedResourceIds([]);
        }
        setLoading('completed');
      } catch (err) {
        console.error(err);
        setLoading('error');
        dispatch(setSelectedResourceContent(undefined));
        setCompletedResourceIds([]);
        dispatch(setSelectedResourceSessionId(undefined));
      }
    }
  };

  const handleAnswerSubmit = async (contentId: string, answer: string[]) => {
    dispatch(
      setSelectedResourceInfo({
        ...selected_resource_info,
        currentAnswer: answer,
      })
    );
    await aiContentQuestionSubmitEvent({
      questionId: contentId,
      answer: answer,
      pageNumber: selected_resource_info?.pageNumber,
      pageContent: selected_resource_info?.pageContent || [],
    });

    const contentIdAttemptCopy = deepClone(contentIdAttempt);
    if (!contentIdAttemptCopy[contentId]) {
      contentIdAttemptCopy[contentId] = new TeacherContentResponse({
        contentId: contentId,
        responses: [],
      });
    }
    contentIdAttemptCopy[contentId].responses.push(
      new ContentAttempt({
        startTime: startTime,
        endTime: Timestamp.fromDate(new Date()),
        answer,
      })
    );
    console.log('contentIdAttemptCopy:', contentIdAttemptCopy);
    updateContentIdAttempt(contentIdAttemptCopy);
  };

  const makeResponseObject = (
    sessionStatus: SessionStatusEnum
  ): UpdateStudentResourceSessionRequest => {
    const contentIdAttemptCopy = deepClone(contentIdAttempt);
    pageContent?.contents.forEach((con) => {
      const contentValue = con.model.value;
      const contentId =
        contentValue instanceof Content
          ? contentValue.contentId
          : contentValue instanceof Question
          ? contentValue.questionId
          : contentValue instanceof ResourceTeacherInstruction
          ? contentValue.resourceId
          : undefined;
      if (contentId) {
        if (!contentIdAttemptCopy[contentId]) {
          contentIdAttemptCopy[contentId] = new TeacherContentResponse({
            contentId: contentId,
          });
        }
        if (
          !(contentValue instanceof Question) ||
          contentIdAttemptCopy[contentId].responses.length == 0
        ) {
          contentIdAttemptCopy[contentId].responses.push(
            new ContentAttempt({
              startTime: startTime,
              endTime: Timestamp.fromDate(new Date()),
              answer: undefined,
            })
          );
        }
      }
    });
    updateContentIdAttempt(contentIdAttemptCopy);
    return new UpdateStudentResourceSessionRequest({
      studentResourceSessionId: activeResourceSessionId,
      studentId: studentId,
      resourceId,
      sessionStatus: sessionStatus,
      endTime:
        sessionStatus === SessionStatusEnum.SESSION_STATUS_EXITED ||
        sessionStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED
          ? Timestamp.fromDate(new Date())
          : undefined,
      response: Object.values(contentIdAttemptCopy),
    });
  };

  function getUpdatedVisitedResourceInfo(
    resourceId: string,
    pageNo: number,
    pageContentIds: string[]
  ) {
    const existingIndex = visitedResourceInfo.findIndex(
      (obj) => obj.resourceId === resourceId && obj.pageNo === pageNo
    );

    if (existingIndex > -1) {
      // If the object with the resourceId and pageNo exists, create a new object with the updated pageContentIds
      const updatedObject = new VisitedResourceContentInfoList({
        ...visitedResourceInfo[existingIndex],
        pageContentIds: Array.from(
          new Set([
            ...visitedResourceInfo[existingIndex].pageContentIds,
            ...pageContentIds,
          ])
        ),
      });
      // Return a new array with the updated object
      return [
        ...visitedResourceInfo.slice(0, existingIndex),
        updatedObject,
        ...visitedResourceInfo.slice(existingIndex + 1),
      ];
    } else {
      // If the object does not exist, return a new array with the new object added
      return [
        ...visitedResourceInfo,
        new VisitedResourceContentInfoList({
          resourceId,
          pageNo,
          pageContentIds,
        }),
      ];
    }
  }

  const makeLessonSessionObject = (
    resourceStatus: SessionStatusEnum
  ): UpdateStudentLessonSessionRequest => {
    const resourceIdsCompletedCopy = completedResourceIds.slice();
    let lessonStatus = resourceStatus;
    if (
      resourceStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED &&
      resourceId
    ) {
      if (!resourceIdsCompletedCopy.includes(resourceId)) {
        resourceIdsCompletedCopy.push(resourceId);
        setCompletedResourceIds(resourceIdsCompletedCopy);
      }
      const resourceIdsOfLesson =
        selectedLessonContent?.resources.map((val) => val.resourceId) || [];
      const notCompletedResources = resourceIdsOfLesson.filter(
        (val) => !resourceIdsCompletedCopy.includes(val)
      );
      lessonStatus =
        notCompletedResources.length > 0 &&
        resourceStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED
          ? SessionStatusEnum.SESSION_STATUS_ONGOING
          : lessonStatus;
    }
    const pageNo = pageContent.pageNumber;
    const conIds = pageContent.contents.map((con) => {
      const contentValue = con.model.value;
      const contentId =
        contentValue instanceof Content
          ? contentValue.contentId
          : contentValue instanceof Question
          ? contentValue.questionId
          : contentValue instanceof ResourceTeacherInstruction
          ? contentValue.resourceTeacherInstructionId
          : undefined;
      return contentId || '';
    });
    const updatedResourceInfo = resourceId
      ? getUpdatedVisitedResourceInfo(resourceId, pageNo, conIds)
      : visitedResourceInfo;
    if (lessonSessionId) {
      dispatch(
        setUpdatedLessonSessionVisitedResourceInfo({
          ...lesson_session_visited_resource_info,
          [lessonSessionId]: updatedResourceInfo,
        })
      );
    }
    return new UpdateStudentLessonSessionRequest({
      studentId: userInfo?.studentProfileId,
      studentLessonSessionId: Number(lessonSessionId),
      lessonId,
      sessionStatus: lessonStatus,
      endTime:
        lessonStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED
          ? Timestamp.fromDate(new Date())
          : undefined,
      sessionMode: sessionMode,
      metrics: new StudentLessonSessionMetrics({
        studentLessonSessionId: Number(lessonSessionId),
        isComplete: lessonStatus == SessionStatusEnum.SESSION_STATUS_COMPLETED,
        responseScore: 0,
        maxScore: 0,
        completedResourceIds: resourceIdsCompletedCopy,
        visitedResourceContentInfoList: updatedResourceInfo,
      }),
    });
  };

  const skipResourceIfLocked = () => {
    if (lessonId && lessonSessionId && topicId && resourceId) {
      const resourceIds =
        selectedLessonContent?.resources.map((val) => val.resourceId) || [];
      const currentIndex = resourceIds.indexOf(resourceId);
      const nextResourceId =
        currentIndex >= 0 ? resourceIds[currentIndex + 1] : undefined;
      if (nextResourceId) {
        onResourceClick(
          navigate,
          {
            resourceId: nextResourceId,
            lessonId: lessonId,
            lessonSessionId: Number(lessonSessionId),
            subjectId: Number(subjectId),
            topicId: Number(topicId),
            chapterId: Number(chapterId),
          },
          SessionModeEnum.SESSION_MODE_LESSON_RESOURCE
        );
      } else {
        dispatch(setSelectedResourceSessionId(undefined));
        onLessonCardClick(dispatch, navigate, {
          subjectId: Number(subjectId),
          lessonId: lessonId,
          topicId: Number(topicId),
          lessonSessionId: Number(lessonSessionId),
          chapterId: Number(chapterId),
        });
      }
      return;
    } else {
      dispatch(
        setToastInfo({
          variant: 'error',
          label: 'Resource is locked',
          open: true,
        })
      );
      navigate(-1);
    }
  };
  const handleResourceCompleteOrEnd = async (
    sessionStatus: SessionStatusEnum
  ) => {
    if (demoResource !== 'true') {
      const resp =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.updateStudentResourceSession(
          makeResponseObject(sessionStatus)
        );
    }
    if (lessonId && lessonSessionId && topicId && resourceId) {
      const resourceIds =
        selectedLessonContent?.resources.map((val) => val.resourceId) || [];
      const currentIndex = resourceIds.indexOf(resourceId);
      const nextResourceId =
        currentIndex >= 0 ? resourceIds[currentIndex + 1] : undefined;
      const isOngoingLessonSession =
        nextResourceId &&
        sessionStatus === SessionStatusEnum.SESSION_STATUS_COMPLETED;
      if (demoResource !== 'true') {
        const resp2 =
          await LessonLearnAPIServiceV1ClientWithStatusCodeHandler.updateStudentLessonSession(
            makeLessonSessionObject(sessionStatus)
          );
      }
      if (isOngoingLessonSession) {
        onResourceClick(
          navigate,
          {
            resourceId: nextResourceId,
            lessonId: lessonId,
            lessonSessionId: Number(lessonSessionId),
            subjectId: Number(subjectId),
            topicId: Number(topicId),
            chapterId: Number(chapterId),
          },
          SessionModeEnum.SESSION_MODE_LESSON_RESOURCE
        );
      } else {
        dispatch(setSelectedResourceSessionId(undefined));
        onLessonCardClick(dispatch, navigate, {
          subjectId: Number(subjectId),
          lessonId: lessonId,
          topicId: Number(topicId),
          lessonSessionId: Number(lessonSessionId),
          chapterId: Number(chapterId),
        });
      }
      return;
    }
    if (resourceRedirectionPath) {
      navigate(resourceRedirectionPath);
      dispatch(setResourceRedirectionPath(undefined));
    } else if (topicId && chapterId) {
      navigate(`${TOPIC_LESSON}/${subjectId}/${chapterId}/${topicId}`);
    } else if (chapterId) {
      navigate(`${LEARN_TOPIC_SELECTION}/${subjectId}/${chapterId}`);
    } else {
      navigate(HOME);
    }
  };

  const onNextClick = async () => {
    await interactionEvent({
      url: 'Student_resource_view',
      context: 'learn',
      name: 'NEXT',
      lessonSesssionId: lessonSessionId,
      resourceSesssionId: activeResourceSessionId?.toString(),
    });

    if (
      selected_resource_info?.currentAnswer &&
      selected_resource_info?.currentAnswer.length === 0
    ) {
      await aiContentCloseEvent({
        pageNumber: pageNumber + 1,
        pageContent: selected_resource_info?.pageContent,
        answer: selected_resource_info?.currentAnswer,
      });
    }

    await aiResourcePageCloseEvent(resourceId, pageNumber + 1);

    if (pageContents[pageNumber + 1]) {
      if (demoResource !== 'true') {
        const resp =
          await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.updateStudentResourceSession(
            makeResponseObject(SessionStatusEnum.SESSION_STATUS_ONGOING)
          );

        if (lessonId && lessonSessionId) {
          const resp2 =
            await LessonLearnAPIServiceV1ClientWithStatusCodeHandler.updateStudentLessonSession(
              makeLessonSessionObject(SessionStatusEnum.SESSION_STATUS_ONGOING)
            );
        }
      }
      await aiResourcePageOpenEvent(resourceId, pageNumber + 2);

      await aiContentOpenEvent({
        resourceId: resourceId,
        pageNumber: pageNumber + 2,
        pageContent: selected_resource_info?.pageContent,
      });

      dispatch(
        setSelectedResourceInfo({
          ...selected_resource_info,
          pageNumber: pageNumber + 2,
          resourceId: resourceId,
          currentAnswer: [],
        })
      );
      setPageNumber(pageNumber + 1);
    } else {
      await aiResourceCloseEvent(resourceId);
      handleResourceCompleteOrEnd(SessionStatusEnum.SESSION_STATUS_COMPLETED);
    }
  };

  const onPreviousClick = async () => {
    if (pageNumber > 0 && pageContents[pageNumber - 1]) {
      await interactionEvent({
        url: 'Student_resource_view',
        context: 'learn',
        name: 'PREV',
        lessonSesssionId: lessonSessionId,
        resourceSesssionId: activeResourceSessionId?.toString(),
      });
      await aiContentCloseEvent({
        pageNumber: pageNumber + 1,
        pageContent: selected_resource_info?.pageContent,
        answer: selected_resource_info?.currentAnswer,
      });
      await aiResourcePageCloseEvent(resourceId, pageNumber + 1);

      await aiResourcePageOpenEvent(resourceId, pageNumber);
      await aiContentOpenEvent({
        resourceId: resourceId,
        pageNumber: pageNumber,
        pageContent: selected_resource_info?.pageContent,
      });

      dispatch(
        setSelectedResourceInfo({
          ...selected_resource_info,
          pageNumber: pageNumber,
          resourceId: resourceId,
        })
      );
      setPageNumber(pageNumber - 1);
    }
  };

  const renderHeading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          flexGrow: 1,
          alignItems: 'center',
          padding: { xs: pxToRem(15), lg: pxTovW(0) },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: { xs: pxToRem(10), md: pxTovW(20) },
          }}
        >
          <ImageWrapper
            name="chapterImage"
            type="png"
            styles={styles.headingImage}
            parentFolder="tempAssets"
            path={getMediaBasePath(
              rawResourceContent?.posterImageUrl,
              rawResourceContent?.resourceCategory ===
                ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                ? 'userDataBucket'
                : 'processedMediaBucket'
            )}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: pxToRem(10),
            }}
          >
            <Typography variant="elementH1">
              {rawResourceContent?.title || ''}
            </Typography>
            <Box sx={styles.iconTextBoxContainer}>
              {[
                {
                  iconName: 'clock',
                  text: (rawResourceContent?.estimatedTimeInMin || 0) + 'Mins',
                },
                {
                  iconName: resourceTypeName(
                    rawResourceContent?.resourceType ||
                      Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED
                  )?.icon,
                  text: resourceTypeName(
                    rawResourceContent?.resourceType ||
                      Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED
                  )?.name,
                },
              ].map((elem, index) => (
                <IconTextBox
                  key={index}
                  iconName={elem.iconName}
                  text={elem.text}
                  index={index}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row-reverse' },
            gap: { md: pxTovW(5) },
            alignItems: { xs: 'center', md: 'flex-start' },
          }}
        >
          {/* <Button variant="contained" color="secondary">
            Add
          </Button> */}
          <Box>
            {isContentLockFeature && (
              <ImageWrapper
                name={
                  resourceId && lockedResourceIds.includes(resourceId)
                    ? 'lock'
                    : 'unlock'
                }
                type="png"
                parentFolder="icons"
                styles={{
                  height: { xs: pxToRem(36), md: pxTovW(58), lg: pxTovW(45) },
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  if (loading === 'error') {
    return (
      <Box
        width={{ xs: undefined, md: '95%' }}
        margin={{ xs: pxToRem(20), md: pxTovW(40) }}
        marginRight={{ xs: pxToRem(20), md: pxTovW(40) }}
      >
        <NoContentCard variant="error" icon="error" text="Error Occured" />
      </Box>
    );
  }

  if (loading === 'loading') {
    return <Loader />;
  }

  if (!resourceId || !activeResourceContent) {
    return (
      <Box>
        <NoContentCard variant="info" icon="cards" text="No Data available" />
      </Box>
    );
  }

  return (
    <Box sx={styles.resourcePage}>
      {pageNumber == 0 && <InPageHeader title={renderHeading()} />}
      <ResourcePageContent
        pageContents={pageContents}
        pageNumber={pageNumber}
        contentIdAttempt={contentIdAttempt}
        handleAnswerSubmit={handleAnswerSubmit}
        selected_resource_info={selected_resource_info}
        showSubmitButton={true}
        eventCollection={{
          answerSelection: async () => {
            await interactionEvent({
              url: '',
              context: 'question',
              name: 'ANSWER_SELECTION',
              pathSegments: pathSegments,
            });
          },
          submitAnswer: async () => {
            await interactionEvent({
              url: '',
              context: 'question',
              name: 'SUBMIT',
              pathSegments: pathSegments,
            });
          },
        }}
        resource_raw_data={rawResourceContent}
      />
      <Box sx={styles.paginationButtonBox}>
        <PaginationButton
          currentPage={pageNumber + 1}
          totalPages={pageContents.length}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Box>
    </Box>
  );
}

interface IBox {
  iconName: string;
  text?: string;
  index: number;
}

const IconTextBox = ({ iconName, text, index }: IBox) => {
  const { isIpadOnly } = useGlobalContext();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: pxToRem(4), md: pxTovW(4) },
        alignItems: 'center',
      }}
    >
      <IconWrapper
        name={iconName}
        size={isIpadOnly ? 'large' : 'md'}
        parentFolder="icons"
        type="png"
      />
      <Typography
        variant="bodyText"
        fontWeight={'bold'}
        color={index % 2 === 0 ? 'primary' : 'secondary'}
      >
        {text}
      </Typography>
    </Box>
  );
};
