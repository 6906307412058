import { useState } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
  FilterSortPopup,
  IStyles,
  SecondaryButton,
  SecondaryOutlinedButton,
  deserify,
  getDeviceType,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { getSubjectsMap } from '../../../../utils/icons';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import { setHomeSelectedSubjectId } from '../../../Home/reducer/homeDashboard.slice';

const styles: IStyles = {
  choicesBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(24) },
    alignItems: 'center',
    mt: { xs: pxToRem(15), md: pxTovW(39) },
    px: {
      xs: `${pxToRem(20)}`,
      md: 0,
    },
  },
  filterBox: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: pxToRem(5), md: pxTovW(7) },
    width: { xs: pxToRem(120), md: pxTovW(140) },
    height: { xs: pxToRem(40), md: pxTovW(56) },
    border: `${pxTovW(1)} solid red`,
    borderColor: 'text.disabled',
    borderRadius: { xs: pxToRem(8), md: pxTovW(10) },
  },

  menuItem: {
    width: { xs: pxToRem(150), md: pxTovW(276) },
    height: { xs: pxToRem(60), md: pxTovW(52) },
    borderTop: `${pxTovW(1)} solid red`,
    borderColor: 'neutral.honeydew',
    p: { md: `${pxTovW(18)} ${pxTovW(21)}` },
  },
};

interface IProps {
  setModalState: (arg: boolean) => void;
  sortFunction: (data: string) => void;
  tab: string;
}

export const HwDashSubjectFilter = ({
  setModalState,
  sortFunction,
  tab,
}: IProps) => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const deviceType = getDeviceType();
  const android = window.Android;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //^ Menu Selection
  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );
  const learnSubjects =
    deserify(useAppSelector((state) => state.auth.userInfo?.learnSubjects)) ||
    [];
  const subMap = getSubjectsMap(learnSubjects);
  const dispatch = useDispatch();

  const aiButtonClickEvent = async () => {
    await interactionEvent({
      url: 'Student_Homework_dash',
      context: 'drop_down',
      name: 'SORT_BY',
      pathSegments: pathSegments,
    });
  };
  const aiButtonElementClickEvent = async (buttonName: string) => {
    await interactionEvent({
      url: 'Student_Homework_dash',
      context: 'sort_by',
      name: buttonName,
      pathSegments: pathSegments,
    });
  };

  return (
    <Box sx={styles.choicesBox}>
      {!mobile && (
        <Box>
          <SecondaryOutlinedButton
            size="medium"
            witharrow={typeof selectedSubjectId == 'undefined'}
            onClick={async () => {
              setModalState(true);
              await interactionEvent({
                url: '',
                context: 'your_homework',
                name: 'ALL_SUBJECTS',
                pathSegments: pathSegments,
              });
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5">
              {typeof selectedSubjectId == 'number'
                ? subMap[selectedSubjectId].subjectName
                : 'All Subjects'}
            </Typography>
            {typeof selectedSubjectId == 'number' && (
              <CloseIcon
                sx={{
                  color: 'common.black',
                  fontSize: { xs: pxToRem(14), md: pxTovW(18) },
                }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  dispatch(setHomeSelectedSubjectId(undefined));
                  try {
                    if (deviceType === 'android' && android?.enableAppRefresh) {
                      android.enableAppRefresh();
                      console.log(
                        'App refresh enabled on Android for home page'
                      );
                    }
                  } catch (error) {
                    console.log(
                      'An error occurred while attempting to enable app refresh on Android:'
                    );
                  }
                }}
              />
            )}
          </SecondaryOutlinedButton>
        </Box>
      )}

      {mobile && (
        <Box sx={{ flexGrow: 1 }}>
          <SecondaryButton
            onClick={async () => {
              setModalState(true);
              await interactionEvent({
                url: '',
                context: 'your_homework',
                name: 'ALL_SUBJECTS',
                pathSegments: pathSegments,
              });
              try {
                if (deviceType === 'android' && android?.disableAppRefresh) {
                  console.log('App refresh disabled on Android for home page');
                  android.disableAppRefresh();
                }
              } catch (error) {
                console.log(
                  'An error occurred while attempting to enable app refresh on Android:'
                );
              }
            }}
            sx={{ position: 'relative', width: '100%' }}
            witharrow={typeof selectedSubjectId == 'undefined'}
          >
            <Typography variant="button">
              {typeof selectedSubjectId == 'number'
                ? subMap[selectedSubjectId].subjectName
                : 'All Subjects'}
            </Typography>
            {typeof selectedSubjectId == 'number' && (
              <CloseIcon
                sx={{
                  position: 'absolute',
                  right: '10px',
                  display: 'inline-block',
                  fontSize: { xs: pxToRem(14), md: pxTovW(18) },
                }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  dispatch(setHomeSelectedSubjectId(undefined));
                  try {
                    if (deviceType === 'android' && android?.enableAppRefresh) {
                      android.enableAppRefresh();
                      console.log(
                        'App refresh enabled on Android for home page'
                      );
                    }
                  } catch (error) {
                    console.log(
                      'An error occurred while attempting to enable app refresh on Android:'
                    );
                  }
                }}
              />
            )}
          </SecondaryButton>
        </Box>
      )}
      <FilterSortPopup
        iconName="Sort"
        title="Sort by"
        options={
          tab === 'Active'
            ? ['Questions', 'Time Left', 'Score']
            : ['Questions', 'Deadline', 'Score']
        }
        sortFunction={sortFunction}
        aiButtonClickEvent={aiButtonClickEvent}
        aiButtonElementClickEvent={aiButtonElementClickEvent}
      />
    </Box>
  );
};
