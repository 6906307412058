import { IStyles, QuestionContainerWithSolution } from '@geneo2-web/shared-ui';
import { Box } from '@mui/material';

import { Question } from '@protos/content_management/content.db_pb';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

declare let window: any;

const styles: IStyles = {};

interface IProps {}
export default function DemoQuestion(props: IProps) {
  const location = useLocation();

  const qNo = new URLSearchParams(location.search).get('qNo') || undefined;

  const [question, setQuestion] = useState<Question | null>(null);

  useEffect(() => {
    // Create a function to handle the event
    const handleDataUpdate = () => {
      console.log({ data: window.jsonDataFromBackend });
      if (window.jsonDataFromBackend) {
        const ques = new Question(window.jsonDataFromBackend);
        console.log(ques);
        setQuestion(ques);
      }
    };

    // Listen for the custom event
    window.addEventListener('jsonDataUpdated', handleDataUpdate);

    // Call the handler initially to check if data is already available
    handleDataUpdate();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('jsonDataUpdated', handleDataUpdate);
    };
  }, []);

  if (!question) {
    return <Box>Error Loading Question...</Box>;
  }

  const ques = new Question(question);
  if (ques.question?.model.value?.commonQuestionContent?.solution) {
    const solution = (
      ques.question.model.value.commonQuestionContent.solution || []
    ).filter((val) => val.model.case !== 'video');
    ques.question.model.value.commonQuestionContent.solution = solution;
  }

  return (
    <div id="question">
      <QuestionContainerWithSolution
        disableInput
        showAnswer
        questionNumber={qNo}
        question={new Question(ques)}
        defaultExpandedAccordion={true}
      />
    </div>
  );
}
