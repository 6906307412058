import { Timestamp } from '@bufbuild/protobuf';
import { BookEventData } from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import { IBookCloseArgs, IBookEventArgs, IBookOpenArgs } from './inputArgs';
import {
  BookCloseArgsValidator,
  BookEventArgsValidator,
  BookOpenArgsValidator,
} from './inputArgsValidator';
import { BOOK_KEY } from './storageKeys';
import {
  checkNgetBookEventData,
  clearEventData,
  getBookEventData,
  getBookSessionData,
  getSessionDataWithIncrimentedIndex,
  hasEventData,
  initialiseBookSession,
  timeDifference,
} from './utils';

export function bookStorageKey(bookId: number) {
  return `${BOOK_KEY}!${bookId}`;
}

export function bookOpen(args: IBookOpenArgs): BookEventData {
  const { error } = BookOpenArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { openTimestamp, bookId } = args;
  const storageKey = bookStorageKey(bookId);
  const bookData = getBookEventData(storageKey);
  const bookSessionId = uuidv4();
  initialiseBookSession({
    bookSessionId,
    bookId,
  });
  if (bookData !== null) {
    throw new Error(
      `Book Data has to be null but ${JSON.stringify(bookData)} is present"`
    );
  } else {
    const eventData = new BookEventData({
      bookId,
      bookSessionId: bookSessionId,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      actionId: bookSessionId,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getBookOpenEvent(args: IBookEventArgs): StudentEvent {
  const { error } = BookEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { bookId, schoolCourseId } = args;
  const storageKey = bookStorageKey(bookId);
  const eventData = checkNgetBookEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  const bookSessionData = getBookSessionData();

  if (!bookSessionData) {
    throw new Error(
      `book Session id can not be undefined in ${JSON.stringify(
        bookSessionData
      )}`
    );
  }

  if (bookSessionData.book === undefined) {
    throw new Error(
      `book Session id can not be undefined in ${JSON.stringify(
        bookSessionData
      )}`
    );
  }

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    bookSessionId: bookSessionData.book.sessionId,
    EventType: {
      case: 'bookAction',
      value: {
        actionId: eventData.actionId,
        bookId,
        schoolCourseId,
        BookActionType: {
          case: 'open',
          value: true,
        },
      },
    },
  });
}

export function bookClose(args: IBookCloseArgs): BookEventData {
  const { error } = BookCloseArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { closeTimestamp, bookId } = args;
  const storageKey = bookStorageKey(bookId);
  const bookData = getBookEventData(storageKey);
  if (!bookData) {
    throw new Error(`Book data not found for bookId: ${bookId}`);
  }
  if (bookData.bookId !== bookId) {
    throw new Error(
      `Book Id in ${JSON.stringify(
        bookData
      )} can't be different from provided "${bookId}"`
    );
  } else {
    bookData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, bookData);
    return bookData;
  }
}

interface BookIdentity {
  bookId: number;
}
export function getBookCloseEvent(args: BookIdentity): StudentEvent {
  const { error } = BookEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { bookId } = args;
  const storageKey = bookStorageKey(bookId);
  const eventData = checkNgetBookEventData(storageKey);

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  const bookSessionData = getBookSessionData();
  if (!bookSessionData) {
    throw new Error(
      `book Session id can not be undefined in ${JSON.stringify(
        bookSessionData
      )}`
    );
  }

  if (bookSessionData.book === undefined) {
    throw new Error(
      `book Session id can not be undefined in ${JSON.stringify(
        bookSessionData
      )}`
    );
  }

  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    bookSessionId: eventData.actionId,
    EventType: {
      case: 'bookAction',
      value: {
        actionId: eventData.actionId,
        bookId,
        schoolCourseId: eventData.schoolCourseId,
        BookActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function clearBookData(args: BookIdentity) {
  const { error } = BookEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { bookId } = args;
  const storageKey = bookStorageKey(bookId);
  clearEventData(storageKey);
}

export function isBookOpen(args: BookIdentity): boolean {
  const { error } = BookEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { bookId } = args;
  const storageKey = bookStorageKey(bookId);
  return hasEventData(storageKey);
}
