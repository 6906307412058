import {
  ActionPopUpSingleButton,
  ChapterSelectedCard,
  IStyles,
  deserify,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { LEARN_DASHBOARD } from '../../../routeHandling/RoutesNomenclature';
import { getSubjectsMap } from '../../../utils/icons';
import SelectedChapterResources from './components/ChapterResources';
import ChooseTopic from './components/ChooseTopic';

import { Module_ModuleCategoryEnum } from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { Feature } from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useDispatch } from 'react-redux';
import {
  setSelectedChapterResources,
  setSelectedChapterTopicInfo,
} from '../reducer/learn.slice';
import {
  aiBookOpenEvent,
  aiChapterCloseEvent,
  aiChapterOpenEvent,
} from '../utils_learn/learn.events';

const styles: IStyles = {
  root: {
    backgroundColor: 'neutral.paleGrey',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: pxTovW(25),
    paddingX: { xs: pxToRem(0), md: pxToRem(20), lg: pxTovW(240) },
    paddingTop: { md: pxTovW(40) },
    boxSizing: 'border-box',
    // width: {
    //   xs: '100%',
    //   md: '100%',
    // },
    // margin: '0 auto',
  },
  topicContainer: {
    paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
    width: {
      xs: '100%',
      md: '40%',
      lg: pxTovW(554),
    },
    // backgroundColor: 'red',
  },
  chapterBox: {
    padding: { xs: pxToRem(20), md: 0 },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
  },
  resourceContainer: {
    width: {
      xs: '100%',
      md: '60%',
      lg: pxTovW(842),
    },
    marginBottom: { xs: pxToRem(43), md: 0 },
    // backgroundColor: 'red',
  },
  chooseTopicBox: {
    display: 'flex',
    gap: { xs: pxToRem(7), md: pxTovW(18) },
    flexDirection: 'column',
  },
};

interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

export default function LearnTopicSelection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { subject_id, chapter_id } = useParams();
  const selectedSubjectId = Number(subject_id);
  const selectedChapterId = Number(chapter_id);

  const learnSubjects =
    deserify(useAppSelector((state) => state.auth.userInfo?.learnSubjects)) ||
    [];
  const subMap = getSubjectsMap(learnSubjects);
  const user = deserify(useAppSelector((state) => state.auth.userInfo));

  const { selected_book_id } = deserify(useAppSelector((state) => state.home));
  const chapterTopic = deserify(
    useAppSelector((state) => state.learn.selected_chapter_topic_info)
  );
  const { userInfo } = deserify(useAppSelector((state) => state.auth));
  const subjectChapterInfo = deserify(
    useAppSelector((state) => state.learn.subject_chapter_info)
  );

  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV2ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const { setSelectedFunction } = useGlobalContext();

  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );

  const [actionPopupState, setActionPopupState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [lockedData, setLockedData] = useState<ContentLockModuleData>();
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });

  useEffect(() => {
    const redirectToLearnDashboard = async () => {
      await aiChapterCloseEvent(Number(chapter_id));
      navigate(`${LEARN_DASHBOARD}/${selectedSubjectId}`);
    };
    setSelectedFunction(() => redirectToLearnDashboard);

    (async () => {
      // const searchedBookId = getBookId(userInfo, subjectChapterInfo?.subjectId);
      // if (!selected_book_id) dispatch(setHomeSelectedBookId(searchedBookId));
      // await aiBookOpenEvent(
      //   selected_book_id ? selected_book_id : searchedBookId
      // );
      await aiChapterOpenEvent(Number(chapter_id));
      await aiEvent();
      await fetchChapterInfo();
    })();

    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const aiEvent = async () => {
    // await aiBookOpenEvent(selected_book_id);
  };

  const fetchLockContent = async () => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.studentModuleLockInfoFetch(
          {
            subjectId: selectedSubjectId,
            studentId: user?.studentProfileId,
            moduleId: selectedChapterId,
            category: Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER,
          }
        );
      if (response?.data) {
        const data = response.data;
        setLockedData(data);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const showLockPopup = () => {
    setPopupDetails({
      iconName: 'popuplock',
      buttontext: 'ok',
      popupText:
        'Your school has locked this resource. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };

  const fetchChapterTopicInfo = async () => {
    try {
      const response =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchChapterTopicInfo(
          {
            personId: user?.studentProfileId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            subjectId: selectedSubjectId,
            chapterId: selectedChapterId,
            sectionId: user?.classSectionDetails?.sectionId,
          }
        );
      if (response?.data) {
        const data = response.data;
        data.chapterTopics.sort((a, b) => a.topicNo - b.topicNo);
        dispatch(setSelectedChapterTopicInfo(response.data));
      } else {
        dispatch(setSelectedChapterTopicInfo(undefined));
      }
    } catch (error) {
      dispatch(setSelectedChapterTopicInfo(undefined));
      throw error;
    }
  };

  const fetchChapterResources = async () => {
    try {
      const response =
        await ContentCommonAPIServiceV2ClientWithStatusCodeHandler.fetchChapterResources(
          {
            personId: user?.studentProfileId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            subjectId: selectedSubjectId,
            chapterId: selectedChapterId,
          }
        );
      if (response?.data) {
        const data = response.data;
        // console.log(typeof data, data);
        dispatch(setSelectedChapterResources(response.data));
      } else {
        dispatch(setSelectedChapterResources(undefined));
      }
    } catch (err) {
      dispatch(setSelectedChapterResources(undefined));
      throw err;
    }
  };

  const fetchChapterInfo = async () => {
    try {
      setLoading(true);
      await fetchChapterTopicInfo();
      await fetchChapterResources();
      if (isContentLockFeature) {
        await fetchLockContent();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  const getSubjectName = () => {
    if (typeof selectedSubjectId == 'number') {
      return subMap[selectedSubjectId].subjectName;
    } else {
      return 'Invalid Subject';
    }
  };

  const getClassDetails = () => {
    if (user?.classSectionDetails?.className) {
      return (
        user?.classSectionDetails?.className +
        (user?.classSectionDetails?.sectionName || '')
      );
    }
    return '';
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.topicContainer}>
        <Box sx={styles.chapterBox}>
          <ChapterSelectedCard
            chapterName={
              chapterTopic?.chapterInfo?.chapterTitle || 'Invalid Chapter'
            }
            image={getMediaBasePath(
              chapterTopic?.chapterInfo?.posterImagesUrl,
              'processedMediaBucket'
            )}
            className={getClassDetails()}
            subject={getSubjectName()}
          />
        </Box>
        <Box sx={styles.chooseTopicBox}>
          <ChooseTopic
            loading={loading}
            error={error}
            lockedData={lockedData}
            showLockPopup={showLockPopup}
          />
        </Box>
      </Box>
      <Box sx={styles.resourceContainer}>
        <SelectedChapterResources
          loading={loading}
          error={error}
          lockedData={lockedData}
          showLockPopup={showLockPopup}
        />
      </Box>
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </Box>
  );
}
