import { Timestamp } from '@bufbuild/protobuf';
import {
  BookSessionData_InteralSessionData,
  SessionData,
} from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { saveMessageLocal } from '../../storage';
import { BOOK_SESSION_KEY } from './storageKeys';
import {
  getBookSessionData,
  getSessionData,
  getSessionDataWithIncrimentedIndex,
  timeDifference,
  writeSessionData,
} from './utils';

export function initialiseBookSession(args: {
  bookSessionId: string;
  bookId: number;
}) {
  const { bookSessionId, bookId } = args;
  const bookSessionData = getBookSessionData();
  bookSessionData.book = new BookSessionData_InteralSessionData({
    sessionId: bookSessionId,
    id: bookId.toString(),
  });
  saveMessageLocal(BOOK_SESSION_KEY, bookSessionData);
}

export function removeBookSession() {
  const bookSessionData = getBookSessionData();
  bookSessionData.book = undefined;
  saveMessageLocal(BOOK_SESSION_KEY, bookSessionData);
}

export function login(args: {
  studentId: number;
  sessionId: string;
  loginTimestamp: Date;
}): SessionData {
  const { studentId, sessionId, loginTimestamp } = args;
  const sessionData: SessionData = new SessionData({
    sessionId,
    eventIndex: 1,
    loginTimestamp: Timestamp.fromDate(loginTimestamp),
    studentId: BigInt(studentId),
  });
  writeSessionData(sessionData);
  return sessionData;
}

interface IGetLogin {
  classId: number;
  sectionId: number;
  schoolId: number;
  boardId: number;
  regionId?: number;
  mediumId: number;
}

export function getLoginEvent(props: IGetLogin): StudentEvent {
  const { classId, sectionId, schoolId, boardId, regionId, mediumId } = props;
  const sessionData = getSessionDataWithIncrimentedIndex();
  if (sessionData.loginTimestamp === undefined) {
    throw new Error(
      `Login timestamp can not be undefined in ${JSON.stringify(sessionData)}`
    );
  }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: sessionData.loginTimestamp,
    eventIndex: sessionData.eventIndex,

    EventType: {
      case: 'login',
      value: {
        classId,
        sectionId,
        schoolId,
        boardId,
        regionId,
        mediumId,
      },
    },
  });
}

export function logout(args: { logoutTimestamp: Date }) {
  const { logoutTimestamp } = args;
  console.log('session.ts/logout : ', args);
  const sessionData = getSessionDataWithIncrimentedIndex();
  sessionData.logoutTimestamp = Timestamp.fromDate(logoutTimestamp);
  writeSessionData(sessionData);
}

export function getLogoutEvent(): StudentEvent {
  const sessionData = getSessionData();
  if (sessionData.loginTimestamp === undefined) {
    throw new Error(
      `Login timestamp can not be undefined in ${JSON.stringify(sessionData)}`
    );
  }
  if (sessionData.logoutTimestamp === undefined) {
    throw new Error(
      `logout timestamp can not be undefined in ${JSON.stringify(sessionData)}`
    );
  }
  // const logoutTimestamp = Timestamp.fromDate(logoutTimestamp);
  const timespent: number = timeDifference(
    sessionData.loginTimestamp,
    sessionData.logoutTimestamp
  );
  // const timespent = 0;

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: sessionData.logoutTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'logout',
      value: {
        timespent,
      },
    },
  });
}

export function initialiseChapterSession(args: {
  chapterSessionId: string;
  chapterId: number;
}) {
  const { chapterSessionId, chapterId } = args;
  const bookSessionData = getBookSessionData();
  bookSessionData.chapter = new BookSessionData_InteralSessionData({
    sessionId: chapterSessionId,
    id: chapterId.toString(),
  });
  saveMessageLocal(BOOK_SESSION_KEY, bookSessionData);
}

export function removeChapterSession() {
  const bookSessionData = getBookSessionData();
  bookSessionData.chapter = undefined;
  saveMessageLocal(BOOK_SESSION_KEY, bookSessionData);
}

export function removeTopicSession() {
  const bookSessionData = getBookSessionData();
  bookSessionData.topic = undefined;
  saveMessageLocal(BOOK_SESSION_KEY, bookSessionData);
}
